import React from 'react'
import CheckboxField from '../../../molecules/fields/CheckboxField'
import { dataToOptions } from '../../../../utils/helpers'
import AutocompleteField from '../../../molecules/fields/AutocompleteField'
import axios from 'axios'
import { useQuery } from 'react-query'
import UbigeoFieldFilter from '../../../molecules/fields/UbigeoFieldFilter'

const fetchCreators = () => axios.get(`/admin/v1/forms/creators.json`).then(res => res.data)

const AnswersFilters = () => {
  const { data: creators } = useQuery(['creators'], fetchCreators, { refetchOnWindowFocus: false })
  const defaultOption = {
    label: 'Seleccionar usuario',
    value: '',
    className: 'border-b-2 border-gray-600 pb-2 w-full'
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div>
        <label className="font-bold" htmlFor="form_type">
          Tipo de formulario
        </label>
        <CheckboxField name="answer_form_type" label="Todos" value="all" margin="none" />
        <CheckboxField name="answer_form_type" label="Formularios simples" value="simple" margin="none" />
        <CheckboxField name="answer_form_type" label="Formularios plantillas" value="template" margin="none" />
        <CheckboxField
          name="answer_form_type"
          label="Form. para consultas rápidas"
          value="quick_search"
          margin="none"
        />
        <CheckboxField name="answer_form_type" label="Form. para gestión de trámites" value="procedure" margin="none" />
      </div>
      <div>
        <UbigeoFieldFilter name="answer" />
      </div>
      <div>
        <AutocompleteField
          label="Creado por"
          options={creators ? [defaultOption, ...dataToOptions(creators)] : []}
          name="answer_created_by"
          size="full"
          margin="none"
          renderOption={option => <span className={option.className}>{option.label}</span>}
          boxClassName="min-w-60 mt-2"
        />
      </div>
    </div>
  )
}

export default AnswersFilters
