import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as validations from '../../../utils/validations'
import { useDropzone } from 'react-dropzone'
import { useField } from 'react-final-form'
import clsx from 'clsx'
import Error from '../Error'
import { DropZoneBox } from '../DropZoneBox'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import {
  humanFileSize,
  findMimeTypeAndExtensionMatch,
  getAcceptedExtensionsFormat
} from '../../../configs/fileTypesConfig'
import { marginSizes } from '../../../configs/sizesConfig'

const FileField = ({
  name,
  payload,
  index,
  required,
  className,
  extensions,
  margin = 'normal',
  fileBackground = 'bg-white',
  onChange,
  hideError = false,
  hideFileUrl = false,
  ariaLabel
}) => {
  const { input } = useField(name, { validate: required && validations.required() })
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const onDrop = acceptedFile => {
    setIsDraggingOver(false)
    const MimeTypeAndExtensionMatch = findMimeTypeAndExtensionMatch(extensions, acceptedFile[0])
    if (MimeTypeAndExtensionMatch) {
      input.onChange(acceptedFile[0])
      onChange && onChange(acceptedFile[0])
    }
  }
  const dropzoneConfig = {
    onDrop,
    multiple: false,
    onDragOver: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false)
  }

  if (extensions) dropzoneConfig.accept = getAcceptedExtensionsFormat(extensions)

  const { getRootProps, getInputProps } = useDropzone(dropzoneConfig)

  return (
    <div className={clsx(marginSizes[margin], 'relative', className, 'js-field-container')}>
      {payload?.label && (
        <label
          aria-describedby={`input-file-${index}-${name}-hint`}
          className="text-lg font-bold"
          htmlFor={`input-file-${index}-${name}`}
        >
          {payload?.label}
        </label>
      )}
      {payload?.hint && (
        <p className="mb-2" id={`input-file-${index}-${name}-hint`}>
          {payload?.hint}
        </p>
      )}
      <DropZoneBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        index={index}
        name={name}
        isHovering={isDraggingOver}
        ariaLabel={ariaLabel}
      />
      {input.value && (
        <div className={clsx(fileBackground, 'rounded-md p-4 mt-4 flex justify-between items-center pr-4')}>
          {input.value.url && !hideFileUrl ? (
            <a href={input.value.url} target="_blank" rel="noreferrer">
              {input.value.name} - {humanFileSize(input.value.size)}
            </a>
          ) : (
            <p>
              {input.value?.name} - {humanFileSize(input.value?.size)}
            </p>
          )}
          <IconButton color="primary" type="button" onClick={() => input.onChange()}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      {!hideError && (
        <div className="relative">
          <Error name={name} />
        </div>
      )}
    </div>
  )
}

export default FileField

FileField.propTypes = {
  className: PropTypes.string,
  extensions: PropTypes.array,
  fileBackground: PropTypes.string,
  hideError: PropTypes.bool,
  hideFileUrl: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  payload: PropTypes.object,
  required: PropTypes.bool,
  ariaLabel: PropTypes.string
}
