import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import IconFilter from '../../../images/icons/filter.svg'
import { ReactSVG as Svg } from 'react-svg'
import clsx from 'clsx'
import useSearchParams from '../../../hooks/useSearchParams'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  expanded: {
    marginTop: '0px !important'
  }
}))

const AccordionFilter = ({ children, accordionHasBackground = true, hasHeader, displayExpandedFilter = false }) => {
  const searchParams = useSearchParams()
  const expanded = Boolean(searchParams.get('filter_open')) || displayExpandedFilter

  const [accordionExpanded, setAccordionExpanded] = useState(expanded)

  const backgroundColor = hasHeader
    ? 'bg-neutral-200'
    : (accordionHasBackground || accordionExpanded) && 'bg-neutral-100'

  const handleChange = (_, state) => setAccordionExpanded(state)

  const classes = useStyles()
  return (
    <Accordion
      classes={{ root: 'shadow-none', expanded: hasHeader && classes.expanded }}
      expanded={accordionExpanded}
      onChange={handleChange}
      data-testid="searchFilter"
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: clsx('md:px-6 px-5 h-16', (accordionExpanded || hasHeader) && backgroundColor),
          content: 'items-center justify-start md:justify-end'
        }}
      >
        <p className="text-blue-700 font-bold flex">Filtros de búsqueda</p>
        <Svg src={IconFilter} className="ml-2 text-blue-700 font-bold flex" />
      </AccordionSummary>

      <AccordionDetails
        classes={{ root: clsx('pt-0 md:px-6 px-5 border-t border-solid border-gray-350', backgroundColor) }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionFilter

AccordionFilter.propTypes = {
  accordionHasBackground: PropTypes.bool,
  children: PropTypes.node,
  hasHeader: PropTypes.bool,
  displayExpandedFilter: PropTypes.bool
}
