import PropTypes from 'prop-types'
import React from 'react'
import { getIndexSubtype, getSubtype } from '../../../configs/questionTypesAdminConfig'
import clsx from 'clsx'

const sizeMap = {
  s: 'text-base',
  m: 'text-2xl'
}

const TitleQuestion = ({ questions, index, parentQuestionIndex, questionsPreviousSteps, size = 'm' }) => {
  const subtype = getSubtype(questions[index].kind)
  const indexSubtype = getIndexSubtype({ questionsPreviousSteps, questions, index, parentQuestionIndex })
  return <h3 className={clsx('font-bold', sizeMap[size])}>{`${subtype} ${indexSubtype}`}</h3>
}
export default TitleQuestion

TitleQuestion.propTypes = {
  index: PropTypes.number,
  parentQuestionIndex: PropTypes.number,
  questions: PropTypes.array,
  questionsPreviousSteps: PropTypes.array,
  size: PropTypes.string
}
