import React from 'react'
import Container from '../../atoms/Container'
import { ReactSVG as Svg } from 'react-svg'
import { Button } from '../../atoms/Button'
import clockLanding from '../../../images/icons/clock_landing.svg'
import screenLanding from '../../../images/icons/screen_landing.svg'
import pointerLanding from '../../../images/icons/pointer_landing.svg'
import headerLanding from '../../../images/home-landing.png'
import { Link } from 'react-router-dom'

export const Header = () => {
  return (
    <div className="">
      <section aria-label="Descripción del servicio" className="bg-neutral-100 pt-14 md:pt-20 md:pb-16 pb-14">
        <Container className="md:px-36 flex justify-between px-8">
          <div className="mb-0 md:mb-10 w-full md:w-5/12">
            <h1 className="font-bold title leading-none mb-4 text-5xl md:text-16">Facilita</h1>
            <p className="text-base md:text-lg leading-tight mb-9">
              Plataforma integral de solicitudes digitales del Estado peruano
            </p>
            <h2 className="mt-4 text-base md:text-xl font-bold mb-6">Digitaliza tus trámites y servicios</h2>
            <div className="flex items-center mb-5 w-80">
              <Svg src={clockLanding} />
              <p className="ml-3 text-base">De manera rápida y sencilla</p>
            </div>
            <div className="flex items-center mb-5 w-80">
              <Svg src={screenLanding} className="" />
              <p className="ml-3 text-base">Sin necesidad de instalación ni mantenimiento</p>
            </div>
            <div className="flex items-center mb-10 w-72">
              <Svg src={pointerLanding} className="" />
              <p className="ml-3 text-base">Con un diseño práctico e intuitivo</p>
            </div>
            <Button el={Link} to="/login" className="hover:text-white md:max-w-56" size="full">
              Ingresa con Gob.pe
            </Button>
          </div>
          <div className="w-148 hidden md:block">
            <img src={headerLanding} alt="header-landing" />
          </div>
        </Container>
      </section>
    </div>
  )
}
