import PropTypes from 'prop-types'
import React from 'react'
import RadioCitizenField from '../question_fields/RadioCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin, onChange } = props
  return (
    <RadioCitizenField
      index={index}
      {...question}
      name={name}
      disabled={disabled}
      margin={margin}
      onChange={onChange}
    />
  )
}

const RadioQuestion = {
  Citizen,
  name: 'RadioQuestion'
}

export default RadioQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  question: PropTypes.object
}
