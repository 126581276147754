import React from 'react'
import CheckboxField from '../components/molecules/fields/CheckboxField'

const privacyPolicyExampleTypeOptions = [
  { value: 'standard', label: 'El ejemplo estándar' },
  { value: 'modified', label: 'Un ejemplo modificado' }
]

const switches = [
  {
    name: 'legal_conditions',
    label:
      'El uso de este plantilla requiere el cumplimiento de condiciones indispensables, según la normativa vigente.'
  },
  { name: 'answers_by_email', label: 'El funcionario podrá recibir las respuestas al correo de su elección.' },
  { name: 'sort_questions', label: 'El funcionario podrá mover el orden de las preguntas.' },
  { name: 'add_questions', label: 'El funcionario podrá crear preguntas y páginas.' },
  { name: 'edit_delete_questions', label: 'El funcionario podrá editar y borrar preguntas.' }
]

const options = [
  { value: 'yes', label: 'Sí' },
  { value: 'no', label: 'No' },
  { value: 'official_decide', label: 'Dejar que el funcionario decida' }
]

const selects = {
  it_costs: {
    label: '¿El trámite requiere realizar un pago?',
    fieldTitle: {
      yes: {
        title: 'Pago del trámite',
        hint: 'Este trámite tiene un costo, indica el monto y medio de pago.'
      },
      official_decide: {
        title: 'Agregar un medio de pago al trámite',
        hint: (
          <CheckboxField
            name="it_costs"
            margin="none"
            label="Este trámite debe tener un medio de pago dentro del formulario."
          />
        )
      },
      no: null
    }
  },
  auto_response: {
    label: '¿El trámite requiere una respuesta automática de la institución pública?',
    fieldTitle: {
      yes: {
        title: 'Respuesta automática',
        hint: 'Se enviará una respuesta automática por correo electrónico a todos los que completen el formulario.'
      },
      official_decide: {
        title: 'Respuesta automática',
        hint: (
          <CheckboxField
            name="auto_response"
            margin="none"
            label="Quiero que se envíe una respuesta automática por correo electrónico a todos los que completen el formulario."
          />
        )
      },
      no: null
    }
  },
  is_trackable: {
    label: '¿El trámite requiere que el ciudadano pueda hacerle seguimiento?',
    fieldTitle: {
      yes: {
        title: 'Seguimiento de trámite',
        hint: 'El ciudadano podrá consultar el estado de su trámite: si este se encuentra en revisión, rechazado o atendido. Además, podrá ver el formulario que envió.'
      },
      official_decide: {
        title: 'Seguimiento de trámite',
        hint: (
          <CheckboxField
            name="is_trackable"
            margin="none"
            label="El ciudadano podrá consultar el estado de su trámite: si este se encuentra en revisión, rechazado o atendido. Además, podrá ver el formulario que envió."
          />
        )
      },
      no: null
    }
  },
  sworn_declaration: {
    label: '¿El trámite tiene carácter de declaración jurada?',
    fieldTitle: {
      yes: {
        title: 'Declaración jurada',
        hint: 'Este formulario tiene carácter de Declaración jurada, para enviarlo, el ciudadano deberá marcar el siguiente recuadro:'
      },
      official_decide: {
        title: 'Declaración jurada',
        hint: (
          <CheckboxField
            name="sworn_declaration"
            margin="none"
            label="Deseo que este formulario tenga carácter de declaración jurada."
          />
        )
      },
      no: null
    }
  }
}

const selectsOptions = Object.keys(selects).map(code => ({ name: code, label: selects[code].label, options }))

const fieldTitle = (code, option) => selects[code].fieldTitle[option]

const FormSteps = {
  CREATION: {
    label: 'Creación de plantilla',
    hint: 'Agrega los campos, opciones y etiquetas que verán los funcionarios públicos en la plantilla'
  },
  PREVIEW: {
    label: 'Vista previa y publicación',
    hint: 'Revisa tu plantilla y publícala para que pueda ser utilizada por los funcionarios públicos.'
  }
}

const formValuesPreview = template => {
  const formValues = { ...template }
  formValues.max_steps = formValues.steps_attributes.length
  formValues.it_costs = template.it_costs === 'yes'
  formValues.sworn_declaration = template.sworn_declaration === 'yes'
  formValues.privacy_policy = { url: null }
  if (formValues.it_costs) {
    formValues.cost = '0'
    formValues.kind_payment = ['bank_transfer']
    formValues.institution = {
      name: 'Nombre de la institución',
      payment_transfer_accounts: [
        { company_name: 'Entidad', ruc: 'RUC', bank: 'bcp', number: 'XXXXXXXXXXXXXXX', cci_number: 'XXXXXXXXXXXXXXX' }
      ]
    }
  }
  return formValues
}
export { privacyPolicyExampleTypeOptions, switches, selectsOptions, fieldTitle, FormSteps, formValuesPreview }
