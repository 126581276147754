import React from 'react'
import CheckboxField from '../../../molecules/fields/CheckboxField'
import SelectFieldFilter from '../../../molecules/filters/SelectFieldFilter'
import InstitutionTypeFieldFilter from '../../../molecules/fields/InstitutionTypeFieldFilter'

const FormsFilters = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div>
        <label className="font-bold" htmlFor="form_type">
          Tipo de formulario
        </label>
        <CheckboxField name="form_type" label="Todos" value="all" margin="none" />
        <CheckboxField name="form_type" label="Formularios simples" value="simple" margin="none" />
        <CheckboxField name="form_type" label="Formularios plantillas" value="template" margin="none" />
        <CheckboxField name="form_type" label="Form. para consultas rápidas" value="quick_search" margin="none" />
        <CheckboxField name="form_type" label="Form. para gestión de trámites" value="procedure" margin="none" />
      </div>
      <div>
        <InstitutionTypeFieldFilter name="form" showInstitutions />
      </div>
      <div>
        <SelectFieldFilter
          className="min-w-60 mb-5 mt-3"
          label="Tipo de usuario"
          name="form_user_role"
          margin="none"
          size="full"
          emptyLabel="Todas"
          options={[
            { id: 'admin', name: 'Administrador' },
            { id: 'coordinator', name: 'Coordinador de entidad' },
            { id: 'branch_coordinator', name: 'Coordinador de sede' },
            { id: 'collaborator', name: 'Colaborador' }
          ]}
        />
      </div>
    </div>
  )
}
export default FormsFilters
