import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import useUserPermission from '../../../hooks/useUserPermission'
import { IconButton } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '../../atoms/Tooltip'
import clsx from 'clsx'

const UserInfoTable = ({ id, name, eliminatorName, eliminatedName, date, hour, hasHour = false, readOnly = false }) => {
  const { canSeeUserInfo } = useUserPermission('user')
  const toolTipTitle = eliminatorName ? `Este usuario ha sido eliminado por ${eliminatorName}` : 'Usuario eliminado'
  const userIsPresent = Boolean(name)
  const deletedUserName = eliminatedName || 'Usuario no definido'

  return (
    <>
      <div>
        {date} {hasHour && hour}
      </div>
      <div>
        por{' '}
        {userIsPresent ? (
          canSeeUserInfo && !readOnly ? (
            <Link to={`/usuarios/${id}/detalles`}>{name}</Link>
          ) : (
            <span className={clsx(readOnly ? 'text-gray-500' : 'font-bold')}>{name}</span>
          )
        ) : (
          <>
            <span className="text-gray-500">{deletedUserName}</span>
            <Tooltip title={toolTipTitle}>
              <IconButton size="small" aria-label={toolTipTitle}>
                <InfoIcon className="text-2xl md:text-lg text-black" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </>
  )
}

export default UserInfoTable

UserInfoTable.propTypes = {
  date: PropTypes.string,
  eliminatorName: PropTypes.string,
  eliminatedName: PropTypes.string,
  hasHour: PropTypes.bool,
  hour: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  readOnly: PropTypes.bool
}
