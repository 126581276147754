import React from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { quickSearchSteps } from '../../../../configs/quickSearchesConfig'
import FormsHeader from '../../../molecules/FormsHeader'
import Preview from '../../../templates/Preview'
import CitizenQuickSearchPage from '../../citizens/CitizenQuickSearchPage'
import useUserPermission from '../../../../hooks/useUserPermission'
import PublishAlert from '../../../molecules/PublishAlert'

const getQuickSearch = id => () => axios.get(`/admin/v1/quick_searches/${id}.json`).then(res => res.data)
const publishQuickSearch = id => values => axios.put(`/admin/v1/quick_searches/${id}/publish.json`, values)
const informationText = {
  linkTitle: 'Enlace de la página de consulta',
  linkHint: 'Una vez publicada la consulta, este es el enlace que deberás compartir con tus usuarios.',
  previewTitle: 'Esta es la página de consulta que verán tus usuarios:',
  previewHint: 'Puedes hacer pruebas y consultar los números de documento de tu base de datos.'
}

const QuickSearchPreview = () => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { data: quickSearch, status } = useQuery(['quickSearch', id], getQuickSearch(id))
  const { mutate } = useMutation(publishQuickSearch(id), {
    onSuccess: () => queryClient.invalidateQueries('quickSearch')
  })
  const { canEdit } = useUserPermission('quickSearch')

  const urlQuickSearch = `${window.location.origin}/c/${id}`
  const edit = canEdit(quickSearch) && {
    url: `/consultas-rapidas/${id}/editar`,
    buttonLabel: 'Editar mi consulta rápida'
  }

  if (status !== 'success') return null

  return (
    <Preview
      steps={
        <FormsHeader
          title={quickSearchSteps[2].label}
          hint={quickSearchSteps[2].hint}
          steps={quickSearchSteps}
          activeStep={quickSearchSteps[2]}
        />
      }
      urlToCopy={urlQuickSearch}
      data={quickSearch}
      publishedUpdate={mutate}
      informationText={informationText}
      edit={edit}
      publishAlert={
        !quickSearch.allow_publish && (
          <PublishAlert serviceName="la consulta" urlToRedirect={`/consultas-rapidas/${id}/editar#privacy_policy`} />
        )
      }
    >
      <CitizenQuickSearchPage isPreview={true} />
    </Preview>
  )
}

export default QuickSearchPreview
