import React, { Fragment, useState } from 'react'
import PageHeading from '../../../molecules/PageHeading'
import Container from '../../../atoms/Container'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { useLocation } from 'react-router-dom'
import useLanguages from '../../../../hooks/useLanguages'
import axios from 'axios'
import Instructions from '../../../molecules/Instructions'
import Alert from '../../../molecules/Alert'
import queryString from 'query-string'
import FieldsSeparator from '../../../atoms/FieldsSeparator'
import Dialog from '../../../molecules/dialogs/Dialog'
import { Button } from '../../../atoms/Button'
import TranslationsTable from '../../../organisms/form_translations/TranslationsTable'
import { useFetchFormDataTranslations } from '../../../../hooks/useFetchFormDataTranslations'

const FormTranslations = () => {
  const {
    id,
    data,
    setData,
    selectedLocale,
    setSelectedLocale,
    createUrl,
    editUrl,
    deleteConfirmation,
    setDeleteConfirmation,
    isProcedureManagement
  } = useFetchFormDataTranslations()

  const [showPopup, setShowPopup] = useState(false)

  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)
  const { languages = [] } = useLanguages('es', 'enabled')

  const handleLocaleChange = event => {
    setSelectedLocale(event.target.value)
  }

  const handleEditClick = () => {
    if (!selectedLocale) {
      setShowPopup(true)
    }
  }

  const handleDeleteClick = (locale, name) => {
    setDeleteConfirmation({ show: true, locale, name })
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const confirmDelete = async () => {
    try {
      const deleteUrl = `/admin/v1/forms/${isProcedureManagement ? data.id : id}/destroy_translations?locale=${
        deleteConfirmation.locale
      }`
      await axios.delete(deleteUrl)
      setData(prevData => ({
        ...prevData,
        translations: prevData.translations.filter(t => t.locale !== deleteConfirmation.locale)
      }))

      setDeleteConfirmation({ show: false, locale: '', name: '' })
    } catch (error) {
      alert(error)
    }
  }

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmation({ show: false, locale: '', name: '' })
  }
  const existingLocales = data?.translations.map(t => t.locale) || []
  const availableLanguages = languages.filter(lang => !existingLocales.includes(lang.code))

  return (
    <Fragment>
      {showPopup && (
        <Dialog
          maxWidth="sm"
          buttonClose={false}
          renderTitle={<p className="header font-bold text-lg">Selecciona un idioma para continuar</p>}
          renderContent={
            <div className="border-neutral-300 py-6">Debes seleccionar un idioma para ingresar traducciones</div>
          }
          onClose={closePopup}
        />
      )}

      {deleteConfirmation.show && (
        <Dialog
          maxWidth="sm"
          buttonClose={false}
          renderTitle={<p className="header font-bold text-lg">Facilita dice:</p>}
          renderContent={
            <p className="py-3">
              ¿Deseas eliminar la versión de este formulario en {deleteConfirmation.name}? El formulario original no se
              eliminará.
            </p>
          }
          renderActions={
            <Fragment>
              <div className="md:contents inline-block">
                <Button
                  size="full"
                  className="md:w-40 w-full md:mr-4 mr-0 md:mb-0 mb-4"
                  onClick={confirmDelete}
                  variant="secondary"
                >
                  Ok
                </Button>
                <Button size="full" className="md:w-40 w-full" onClick={handleCloseDeleteDialog}>
                  Cancelar
                </Button>
              </div>
            </Fragment>
          }
          onClose={handleCloseDeleteDialog}
        />
      )}

      {data && <PageNavigation breadcrumbData={data} />}
      <Container>
        {urlSearchParams.action === 'translation_saved' && (
          <Alert
            showClose
            type="success"
            className="mt-4"
            displayPositionClassName="fixed md:bottom-6 mb-6 p-6 rounded-lg shadow-lg z-50 transform -translate-x-1/2 left-1/2 flex md:w-1/3 w-11/12 text-green-800"
            iconCloseColorClassName="text-blue-700 text-lg"
            iconButtonClassName="text-blue-700"
            showTextClose
          >
            Registro exitoso de traducción de formulario
          </Alert>
        )}
        <PageHeading
          title="Habilitar idiomas adicionales para el formulario"
          pageDescription="Agrega idiomas adicionales a los que traduciras tu formulario"
          boxTableClasses="pt-4"
        />

        <div className="overflow-x-scroll md:overflow-x-auto bg-neutral-100 md:p-12 p-5 md:mt-0 mt-8 rounded">
          <h2 className="font-bold md:text-2xl">Información y configuración general</h2>
          <Instructions
            list={[
              'Antes de ingresar las traducciones, debes guardar la versión inicial del formulario.',
              'Si no quieres agregar la traducción de un campo, copia el enunciado en español. '
            ]}
          />
          <FieldsSeparator className="mb-0 mt-6" />
          {data && (
            <TranslationsTable
              translations={data.translations}
              availableLanguages={availableLanguages}
              onEdit={handleEditClick}
              toNew={createUrl}
              toEdit={editUrl}
              onDelete={handleDeleteClick}
              onLocaleChange={handleLocaleChange}
              selectedLocale={selectedLocale}
            />
          )}
        </div>
      </Container>
    </Fragment>
  )
}

export default FormTranslations
