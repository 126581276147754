import React from 'react'
import PageHeading from '../../../molecules/PageHeading'
import NotificationsBody from '../../../organisms/notifications/NotificationsBody'
import Alert from '../../../molecules/Alert'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const Notifications = () => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)
  return (
    <div>
      {urlSearchParams.action === 'notification_created' && (
        <Alert type="success" className="mt-10">
          Notificación ha sido programada.
        </Alert>
      )}
      {urlSearchParams.action === 'notification_send' && (
        <Alert type="success" className="mt-10">
          Notificación enviada con éxito.
        </Alert>
      )}

      <PageHeading
        title="Panel de notificaciones"
        pageDescription="Envía notificaciones a los funcionarios usuarios de Facilita."
        button={{
          link: '/notificaciones/crear',
          name: 'Enviar nueva notificación'
        }}
      />
      <NotificationsBody />
    </div>
  )
}
