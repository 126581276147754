import { Button } from '../../atoms/Button'
import { ReactSVG as Svg } from 'react-svg/dist'
import download from '../../../../assets/images/download.svg'
import { getSpreadsheetPath, options } from '../../../configs/quickSearchesConfig'
import FileField from '../../molecules/fields/FileField'
import ErrorValidationMessage from '../../molecules/ErrorValidationMessage'
import spreadsheetError from './spreadsheetError'
import FormSection from '../../molecules/FormSection'
import React from 'react'
import useFieldValue from '../../../hooks/useFieldValue'
import { useField } from 'react-final-form'
import RadioField from '../../molecules/fields/RadioField'
import CsvSeparatorModal from './CsvSeparatorModal'
import useToggle from '../../../hooks/useToggle'
import * as validations from '../../../utils/validations'
import useUserPermission from '../../../hooks/useUserPermission'
import { TextButton } from '../../molecules/buttons/TextButton'

const extensions = ['csv']

const SpreadsheetSection = () => {
  const validationType = useFieldValue('validation_type')
  const { meta } = useField('spreadsheet')
  const [csvSeparatorModal, setCsvSeparatorModal] = useToggle()
  const { canNotSeeExcelUrl } = useUserPermission('quickSearch')

  return (
    <FormSection title="Completa y sube la base de datos en éxcel">
      <p className="mb-6">
        Descarga la plantilla de éxcel y completa manualmente la información para cada documento de identidad. Este
        archivo conecta el número de documento con el tipo de resultado correspondiente (A, B, C, etc.). Si tus
        resultados tienen variables ($1, $2, $3, etc.), no olvides completar dichas columnas.
        <span className="font-bold"> Importante: debes guardar el archivo en formato .csv.</span>
      </p>
      <a className="mb-6 font-bold" href="/admin/consultas-rapidas/ejemplo-formato" target="_blank">
        Ver ejemplo
      </a>
      <p className="mt-6 mb-2">1. Descarga el éxcel y complétalo con los datos de tu consulta.</p>
      <Button
        className="flex mb-6"
        size="l"
        el="a"
        variant="secondary"
        startIcon={<Svg src={download} className="mt-1 mr-2" />}
        href={getSpreadsheetPath(validationType)}
      >
        Descarga plantilla
      </Button>
      <p>
        2. Selecciona el tipo de separador que tiene tu archivo .csv.{' '}
        <TextButton onClick={setCsvSeparatorModal}>¿Dónde veo esto?</TextButton>
      </p>
      {csvSeparatorModal && <CsvSeparatorModal onClose={() => setCsvSeparatorModal()} />}
      <RadioField
        name="col_separator"
        options={options.colSeparator}
        validate={validations.required()}
        errorClassName={'-bottom-6'}
        margin="high"
      />
      <p className="mb-2">3. Sube tu archivo .csv modificado.</p>
      <FileField
        name="spreadsheet"
        ariaLabel="Excel File"
        extensions={extensions}
        required
        hideError
        hideFileUrl={canNotSeeExcelUrl}
      />
      {!meta.dirtySinceLastSubmit && (meta.submitError || meta.error) && (
        <div className="relative js-field-container">
          <ErrorValidationMessage error={spreadsheetError(meta.submitError) || meta.error} />
        </div>
      )}
    </FormSection>
  )
}

export default SpreadsheetSection
