import PropTypes from 'prop-types'
import React from 'react'
import RadioField from '../../molecules/fields/RadioField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'
import { useTranslation } from 'react-i18next'

const RadioCitizenField = ({ name, index, payload, required, disabled = false, margin, onChange }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const { t } = useTranslation(['form_field_options'])
  const otherLabel = t('radio_checkbox_button_other', { ns: 'form_field_options' })
  const options = (payload?.alternatives || []).map(o => ({
    value: o.label,
    label: o.type === 'other' ? otherLabel : o.label,
    type: o.type
  }))
  const translationText = useTextValidation()
  return (
    <RadioField
      name={`${name}.selected`}
      baseName={name}
      label={label}
      hint={payload?.hint}
      options={options}
      disabled={disabled}
      validate={required && validations.required(translationText.required)}
      otherValidate={required && validations.required(translationText.required)}
      margin={margin}
      onChange={onChange}
      allowClearSelection
    />
  )
}

export default RadioCitizenField

RadioCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  payload: PropTypes.object,
  required: PropTypes.bool
}
