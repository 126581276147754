import PropTypes from 'prop-types'
import React from 'react'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import useTextValidation from '../../../hooks/useTextValidation'

const DropdownCitizenField = ({ name, index, payload, required, disabled = false, margin, onChange }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const options = [...(payload?.alternatives || []).map(o => ({ value: o.label, label: o.label }))]
  const emptyLabel = payload.has_indication_text ? payload.indication_text : undefined
  const translationText = useTextValidation()

  return options.length < 10 ? (
    <SelectField
      name={name}
      label={label}
      className="block"
      hint={payload?.hint}
      options={options}
      disabled={disabled}
      validate={required && validations.required(translationText.required)}
      margin={margin}
      onChange={onChange}
      size="2xl"
      emptyLabel={emptyLabel}
    />
  ) : (
    <AutocompleteField
      name={name}
      label={label}
      hint={payload?.hint}
      options={options}
      margin={margin}
      size="2xl"
      onChange={onChange}
      validate={required && validations.required(translationText.required)}
      disabled={disabled}
      emptyLabel={emptyLabel}
    />
  )
}

export default DropdownCitizenField

DropdownCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  payload: PropTypes.object,
  required: PropTypes.bool
}
