import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Container from '../../atoms/Container'
import { options, quickSearchSteps, headerInfo } from '../../../configs/quickSearchesConfig'
import FormSection from '../../molecules/FormSection'
import FileField from '../../molecules/fields/FileField'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { HtmlField } from '../../molecules/fields/HtmlField'
import SelectField from '../../molecules/fields/SelectField'
import Value from '../../molecules/Value'
import RadioField from '../../molecules/fields/RadioField'
import antispam from '../../../../assets/images/antispam.svg'
import PrivacyPolicyCreator from '../forms/PrivacyPolicyCreator'
import { Form as FinalForm } from 'react-final-form'
import { clearMutator } from '../../../utils/formMutators'
import { focusOnFirstError, preventSubmitPrivacyPolicy } from '../../../utils/decorators'
import SaveButton from '../../molecules/buttons/SaveButton'
import FormsHeader from '../../molecules/FormsHeader'
import { useHistory } from 'react-router-dom'
import { serialize } from 'object-to-formdata'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import { PageNavigation } from '../navigation/PageNavigation'

const extensions = ['jpg', 'png', 'bmp']

const QueryPageForm = ({ initialValues, action, mutate }) => {
  const history = useHistory()
  const [uploadProgress, setUploadProgress] = useState(null)

  const handleSubmit = values => {
    const formData = serialize(values, { indices: true })
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      onSuccess: ({ id }) => history.push(`/consultas-rapidas/${id || values.id}/resultados?action=${action}`)
    }
    try {
      mutate(values.id, formData, config)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FinalForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          mutators={{ clear: clearMutator }}
          decorators={[focusOnFirstError, preventSubmitPrivacyPolicy]}
        >
          {({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              <>
                <FormsHeader
                  title={headerInfo.title}
                  hint={headerInfo.hint}
                  steps={quickSearchSteps}
                  activeStep={quickSearchSteps[0]}
                />
                <FormSection title="Información y configuración general">
                  <p className="font-bold mb-1">Insertar logo (opcional)</p>
                  <p className="mb-2">
                    La imagen no debe pesar más de 1 MB. Se recomienda 150 pixeles de alto x 450 de ancho. Los formatos
                    permitidos son JPG, PNG y BMP.
                  </p>
                  <FileField name="logo" ariaLabel="Logo" extensions={extensions} />
                  <FieldsSeparator />
                  <TextField
                    name="name"
                    label="Nombre de la página de consulta"
                    hint={
                      <span>
                        <span className="font-bold">Por ejemplo:</span> Consulta el padrón de canasta básica familiar
                      </span>
                    }
                    size="2xl"
                    validate={validations.required()}
                  />
                  <TextField
                    name="hint"
                    label="Descripción de la página de consulta"
                    hint={
                      <span>
                        Describe de manera breve el tipo de consulta que realizará el ciudadano.
                        <span className="font-bold">
                          <br /> Por ejemplo:
                        </span>{' '}
                        Entérate si tu hogar es beneficiario de la canasta básica familiar.
                      </span>
                    }
                    size="full"
                    validate={validations.required()}
                  />
                  <HtmlField
                    name="informative_text"
                    label="Texto informativo de la página de consulta"
                    hint="Es la información que se le dará al ciudadano sobre la consulta que va realizar."
                    validate={validations.required()}
                  />
                  <FieldsSeparator />
                  <div className="grid md:gap-6 md:grid-cols-2">
                    <SelectField
                      name="document_type"
                      label="Tipo de documento"
                      hint="Selecciona qué tipo de documento se usará en la búsqueda:"
                      options={options.documentType}
                      size="full"
                      validate={validations.required()}
                    />
                    <WhenFieldChanges field="document_type" set="other_document_label" to={null} />
                    <Value name="document_type">
                      {({ value }) =>
                        value === 'other_document' && (
                          <TextField
                            name="other_document_label"
                            hint={
                              <>
                                <span className="font-bold">Etiqueta del tipo de documento.</span> Ejm: Código de
                                expediente
                              </>
                            }
                            validate={validations.required()}
                            size="full"
                            rootClassName="self-end"
                          />
                        )
                      }
                    </Value>
                  </div>
                  <RadioField
                    name="validation_type"
                    label="Dato de validación"
                    hint="Si tu página lo requiere, puedes usar como dato de validación la fecha de nacimiento del ciudadano. Si seleccionas esta opción, deberás incluir ese dato en tu base de datos."
                    options={options.validation}
                    defaultValue={options.validation[0].value}
                  />
                </FormSection>
                <FormSection>
                  <p className="font-bold mt-6">Protección antispam</p>
                  <p className="mb-2" id="">
                    Este sistema de protección, llamado CAPTCHA, aparecerá de manera predeterminada y brindará
                    protección integral contra el spam y entradas remotas sospechosas. Ejemplo de visualización:
                  </p>
                  <img alt="Antispam" src={antispam} />
                  <FieldsSeparator />
                  <PrivacyPolicyCreator required={false} serviceType="quick_search" />
                </FormSection>
                <div className="w-full flex justify-end">
                  <SaveButton
                    disabled={submitting}
                    containerClassName="w-full"
                    label={'Guardar y continuar'}
                    uploadProgress={uploadProgress}
                  />
                </div>
              </>
            </form>
          )}
        </FinalForm>
      </Container>
    </>
  )
}
export default QueryPageForm

QueryPageForm.propTypes = {
  action: PropTypes.string,
  initialValues: PropTypes.object,
  mutate: PropTypes.func.isRequired
}
