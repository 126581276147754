import PropTypes from 'prop-types'
import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useField } from 'react-final-form'
import clsx from 'clsx'
import Error from '../Error'
import { ReactSVG as Svg } from 'react-svg'
import uncheckedIcon from '../../../images/icons/unchecked.svg'
import checkedIcon from '../../../images/icons/checked.svg'
import disabledCheckedIcon from '../../../images/icons/disabled_checked.svg'
import disabledUncheckedIcon from '../../../images/icons/disabled_unchecked.svg'
import { marginSizes } from '../../../configs/sizesConfig'
import OtherField from './OtherField'
import WhenFieldChanges from '../WhenFieldChanges'
import { findArrayDifference } from '../../../utils/helpers'

const rippleSizes = {
  small: 'p-1.25 ml-1',
  normal: ''
}

const CheckboxField = ({
  name,
  baseName,
  validate,
  otherValidate,
  label,
  margin = 'normal',
  rippleSize = 'normal',
  className,
  hideError = false,
  disabled = false,
  total,
  noYpadding = false,
  type,
  value,
  inputProps
}) => {
  const { input } = useField(name, { validate, type: 'checkbox', value })
  const otherChecked = type === 'other' && input.checked

  const handleCondition = (a, b) => {
    return a !== b && findArrayDifference(a, b).includes(value) && type === 'other'
  }

  return (
    <div
      className={clsx(
        className,
        marginSizes[margin],
        total ? 'justify-between' : '',
        'flex items-center flex-wrap relative js-field-container'
      )}
    >
      <div className="flex gap-4 items-start flex-col md:flex-row">
        <FormControlLabel
          control={
            <Checkbox
              className={clsx('text-gray-800', rippleSizes[rippleSize])}
              {...input}
              {...inputProps}
              name={name}
              disabled={disabled}
              color="secondary"
              style={{
                color: '#26292e'
              }}
              classes={{ root: noYpadding && 'py-0' }}
              icon={<Svg src={disabled ? disabledUncheckedIcon : uncheckedIcon} />}
              checkedIcon={<Svg src={disabled ? disabledCheckedIcon : checkedIcon} />}
            />
          }
          classes={{ label: 'text-base', root: clsx(`mr-0`, otherChecked && 'mt-3') }}
          label={label}
        />
        <WhenFieldChanges field={name} set={`${baseName}.other_text`} to={null} condition={handleCondition} />
        <OtherField name={baseName} open={otherChecked} validate={otherValidate} />
      </div>
      {total ? <p className="text-right self-center">({total})</p> : null}
      {hideError ? null : <Error name={name} className="absolute -bottom-4" />}
    </div>
  )
}

export default CheckboxField

CheckboxField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  label: PropTypes.node,
  margin: PropTypes.string,
  name: PropTypes.string,
  baseName: PropTypes.string,
  noYpadding: PropTypes.bool,
  rippleSize: PropTypes.string,
  total: PropTypes.number,
  validate: PropTypes.func,
  otherValidate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.object
}
