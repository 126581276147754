import React from 'react'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import Form from '../../../organisms/templates/Form'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import FormsHeader from '../../../molecules/FormsHeader'
import useTemplate from '../../../../hooks/useTemplate'
import { useMutation, useQueryClient } from 'react-query'

const updateForm =
  id =>
  ({ values, config }) =>
    axios.put(`/admin/v1/templates/${id}.json`, values, config).then(res => res.data)

const TemplatesEdit = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { data: template, status } = useTemplate(id)
  const { mutate } = useMutation(updateForm(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['template', id])
      history.push(`/plantillas/${id}/vista-previa?action=edit`)
    }
  })
  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="pt-12">
        <FormsHeader
          title={`Editando plantilla: ${template.name}`}
          hint="Modifica, agrega y/o elimina los campos que verán los funcionarios públicos en la plantilla"
        />
        <Form template={template} mutateForm={mutate} />
      </Container>
    </>
  )
}
export default TemplatesEdit
