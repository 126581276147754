import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const Analytics = ({ trackingId }) => {
  const location = useLocation()
  useEffect(() => {
    if (!window.gtag) return
    window.gtag('config', trackingId, { page_path: `${location.pathname}${location.search}` })
  }, [location])
  return null
}

Analytics.propTypes = {
  trackingId: PropTypes.string
}

export default Analytics
