import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import FormsNewSetup from '../../components/pages/admin/forms/FormsNewSetup'
import FormsNewFromTemplate from '../../components/pages/admin/forms/FormsNewFromTemplate'
import FormsNewFromTemplateCondition from '../../components/pages/admin/forms/FormsNewFromTemplateCondition'
import FormsNew from '../../components/pages/admin/forms/FormsNew'
import FormsEdit from '../../components/pages/admin/forms/FormsEdit'
import Answer from '../../components/pages/admin/forms/Answer'
import FormPreview from '../../components/pages/admin/forms/FormPreview'
import Answers from '../../components/pages/admin/forms/Answers'
import AnswerExport from '../../components/pages/admin/forms/AnswerExport'
import FormNewTranslation from '../../components/pages/admin/form_translations/FormNewTranslation'
import FormEditTranslation from '../../components/pages/admin/form_translations/FormEditTranslation'
import FormTranslations from '../../components/pages/admin/form_translations/FormTranslations'
import useUserPermission from '../useUserPermission'

const useFormsRoute = () => {
  const { canCreateOrEdit } = useUserPermission('form')

  return [
    { path: '/formularios', component: ResourcesTab, hasPermission: true },
    { path: '/formularios/crear/configuracion', component: FormsNewSetup, hasPermission: canCreateOrEdit },
    {
      path: '/formularios/crear/plantilla/:template_id',
      component: FormsNewFromTemplate,
      hasPermission: canCreateOrEdit
    },
    {
      path: '/formularios/crear/condiciones/:template_id',
      component: FormsNewFromTemplateCondition,
      hasPermission: canCreateOrEdit
    },
    { path: '/formularios/crear', component: FormsNew, hasPermission: canCreateOrEdit },
    { path: '/formularios/:id/editar', component: FormsEdit, hasPermission: canCreateOrEdit },
    { path: '/formularios/:id/traducciones', component: FormTranslations, hasPermission: canCreateOrEdit },
    { path: '/formularios/:id/crear-traduccion', component: FormNewTranslation, hasPermission: canCreateOrEdit },
    { path: '/formularios/:id/editar-traduccion', component: FormEditTranslation, hasPermission: canCreateOrEdit },
    { path: '/formularios/:form_id/respuestas/:answer_id', component: Answer, hasPermission: true },
    { path: '/formularios/:id/vista-previa', component: FormPreview, hasPermission: true, hasFeedback: true },
    { path: '/formularios/:id/respuestas', component: Answers, hasPermission: true },
    { path: '/formularios/:id/exportaciones', component: AnswerExport, hasPermission: true }
  ]
}
export default useFormsRoute
