import PropTypes from 'prop-types'
import React from 'react'
import DateField from '../question_config_fields/DateField'
import * as validations from '../../../utils/validations'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import TextWithInfoField from '../../molecules/TextWithInfoField'

const Form = props => {
  return <DateField baseName={props.baseName} validate={validations.required()} />
}

const Template = props => {
  return (
    <TextWithInfoField
      payload={props.question.payload}
      name={props.name}
      infoContent="El usuario verá un selector de fechas."
      disabled
    />
  )
}

const DateQuestion = {
  selectOption: { value: 'date', label: 'Fecha', icon: <CalendarTodayIcon fontSize="small" /> },
  Form,
  Template,
  name: 'DateQuestion'
}

export default DateQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
