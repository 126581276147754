import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import FlipMove from 'react-flip-move'
import * as uuid from 'uuid'
import { useFieldArray } from 'react-final-form-arrays'
import AddIcon from '@material-ui/icons/Add'
import { Button } from '../../atoms/Button'
import Error from '../../molecules/Error'
import SubQuestions from './SubQuestions'
import { getIndexSubtype, isSubtitleFromTemplate, showSplitPage } from '../../../configs/questionTypesAdminConfig'
import UpDownQuestions from './UpDownQuestions'
import QuestionCard from './QuestionCard'
import { ReactSVG as Svg } from 'react-svg'
import pageSplitIcon from '../../../images/icons/page_split.svg'
import { getNextActiveIndex, isFirst } from '../../../utils/destroyableFields'
import useCurrentStep from '../../../hooks/useCurrentStep'
import FieldsSeparator from '../../atoms/FieldsSeparator'

const getTestId = (parentQuestionIndex, stepIndex, questionIndex) =>
  parentQuestionIndex ? `subpregunta-${parentQuestionIndex}.${questionIndex}` : `pregunta-${stepIndex}.${questionIndex}`

const QuestionsFields = ({
  name,
  canDo = { addQuestions: true, addPages: true, sortQuestions: true, editDeleteQuestions: true },
  editableAlternatives,
  parentQuestionIndex,
  validate,
  stepIndex
}) => {
  const step = useCurrentStep(stepIndex)
  const baseName = name
  const { fields } = useFieldArray(name, { validate })
  return (
    <div className="relative js-field-container">
      <FlipMove enterAnimation="none" leaveAnimation="none">
        {fields.map((name, index) => {
          const indexSubtype = getIndexSubtype({
            questionsPreviousSteps: step.questionsPrevious,
            questions: fields.value,
            index
          })
          return (
            !fields.value[index]?._destroy && (
              <Fragment key={fields.value?.[index]?.key}>
                {!isFirst(fields.value, index) && isSubtitleFromTemplate(fields.value?.[index]) && <FieldsSeparator />}
                <div
                  className="flex flex-col md:flex-row mb-8 flex-wrap relative"
                  data-testid={getTestId(parentQuestionIndex, stepIndex, index)}
                >
                  <QuestionCard
                    baseName={baseName}
                    index={index}
                    fields={fields}
                    editableAlternatives={editableAlternatives}
                    parentQuestionIndex={parentQuestionIndex}
                    questionsPreviousSteps={step.questionsPrevious}
                    stepIndex={stepIndex}
                    editDeleteQuestions={canDo.editDeleteQuestions}
                  />
                  {canDo.sortQuestions && <UpDownQuestions fields={fields} index={index} step={step} />}
                  {fields.value[index]?.has_sub_questions && (
                    <SubQuestions
                      name={name}
                      parentQuestionIndex={indexSubtype}
                      tabs={fields.value[index].payload?.alternatives}
                      canDo={canDo}
                    />
                  )}
                  <div className="md:w-2/12 lg:w-1/12 md:ml-auto md:pl-4 ">
                    {showSplitPage(fields.value, index, parentQuestionIndex) && canDo.addPages && (
                      <Button
                        variant="text"
                        className="text-sm text-purple-700 leading-4 md:absolute md:right-0 md:-bottom-10 w-full md:max-w-24 bg-neutral-200 md:bg-neutral-100 my-6 md:my-0"
                        classes={{ label: 'text-left' }}
                        size="none"
                        onClick={() => {
                          const nexActiveQuestion = getNextActiveIndex(fields, index)
                          fields.splitPage(index, step.index)
                          fields.markForDestroy(nexActiveQuestion)
                        }}
                        startIcon={<Svg src={pageSplitIcon} />}
                      >
                        Dividir página
                      </Button>
                    )}
                  </div>
                </div>
              </Fragment>
            )
          )
        })}
      </FlipMove>
      {canDo.addQuestions && (
        <Button
          startIcon={<AddIcon />}
          variant="secondary"
          type="button"
          className="md:max-w-56 py-3 px-4"
          size="full"
          onClick={() => fields.push({ key: uuid.v4() })}
        >
          Agrega pregunta
        </Button>
      )}
      <Error name={fields.name} />
    </div>
  )
}

export default QuestionsFields

QuestionsFields.propTypes = {
  canDo: PropTypes.object,
  editableAlternatives: PropTypes.bool,
  name: PropTypes.string,
  parentQuestionIndex: PropTypes.number,
  stepIndex: PropTypes.number,
  validate: PropTypes.func
}
