import React from 'react'
import Container from '../../atoms/Container'
import informationCardImage1 from '../../../images/information_1_landing.svg'
import informationCardImage2 from '../../../images/information_2_landing.svg'
import informationCardImage4 from '../../../images/information_1_1_landing.png'
import informationCardImage5 from '../../../images/information_2_1_landing.png'

export const WhatCanIDo = () => {
  const cards = [
    {
      title: (
        <span>
          Formularios de trámites y <br /> encuestas
        </span>
      ),
      body: 'Digitaliza tus trámites para los ciudadanos y acelera los procesos de atención dentro de tu entidad.',
      imgDesktop: informationCardImage4,
      imgMobile: informationCardImage1
    },
    {
      title: 'Páginas de consultas',
      body: 'Crea páginas donde los ciudadanos consulten sobre beneficios o temas pendientes de tu entidad.',
      imgDesktop: informationCardImage5,
      imgMobile: informationCardImage2
    }
  ]

  return (
    <section className="pt-14 md:pt-20 pb-14 md:pb-24">
      <Container>
        <h2 className="mb-7 md:mb-12 font-bold text-xl md:text-7.5 text-center">¿Qué puedo hacer con Facilita?</h2>
        <ul className="flex flex-nowrap container-cards md:container md:justify-center overflow-x-auto md:overflow-hidden pb-2 md:px-64">
          {cards.map(card => (
            <li key={card} className="flex-none md:flex-auto w-80 md:w-148 card md:ml-4 md:mr-4">
              <div className="border-l border-r border-t rounded-t-md rounded-b-sm md:rounded-lg border-solid border-neutral-300 flex flex-col md:flex-row h-full overflow-hidden">
                <div className="border-r-0 md:border-r-0.375 md:w-56">
                  <img className="md:block hidden" src={card.imgDesktop} alt="" loading="lazy" />
                  <img className="md:hidden block" src={card.imgMobile} alt="" loading="lazy" />
                </div>
                <div className="px-6 pb-6 md:w-64">
                  <h3 className="mt-6 text-base font-bold mb-4">{card.title}</h3>
                  <p className="text-base">{card.body}</p>
                  <div className="flex justify-end mt-6"></div>
                </div>
              </div>
              <div className="bg-blue-700 w-full pt-2 rounded-b md:-mt-1 md:relative" />
              {/* <Card image={card.img}>
                <h3 className="mt-4 text-base font-bold py-2 mb-2">{card.title}</h3>
                <p className="text-base">{card.body}</p>
                <div className="flex justify-end mt-6"></div>
              </Card> */}
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
