import PropTypes from 'prop-types'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from './IconButton'

const DeleteButton = ({ onClick }) => {
  return (
    <IconButton text="Eliminar" onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  )
}

export default DeleteButton

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
