import PropTypes from 'prop-types'
import React from 'react'
import HourField from '../question_config_fields/HourField'
import * as validations from '../../../utils/validations'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import TextWithInfoField from '../../molecules/TextWithInfoField'

const Form = props => {
  return <HourField baseName={props.baseName} validate={validations.required()} />
}

const Template = props => {
  return (
    <TextWithInfoField
      payload={props.question.payload}
      name={props.name}
      infoContent="El usuario verá un selector de horas y minutos."
      disabled
    />
  )
}

const HourQuestion = {
  selectOption: { value: 'hour', label: 'Hora', icon: <QueryBuilderIcon fontSize="small" /> },
  Form,
  Template,
  name: 'HourQuestion'
}

export default HourQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
