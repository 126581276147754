import PropTypes from 'prop-types'
import CheckboxField from '../../molecules/fields/CheckboxField'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import React from 'react'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import IconButton from '../../molecules/buttons/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import DropdownIndicationExample from '../../../images/dropdown_indication_example.png'
import useFieldValue from '../../../hooks/useFieldValue'

const DropdownIndicationText = ({ baseName }) => {
  const hasIndicationText = useFieldValue(`${baseName}.has_indication_text`)
  return (
    <div className="w-full mt-1">
      <div className="flex items-center">
        <CheckboxField
          className="ml-0.5"
          name={`${baseName}.has_indication_text`}
          margin="none"
          label="Modificar texto de indicación"
          noYpadding
        />
        <IconButton
          asTooltip
          tooltipProps={{ leaveTouchDelay: 10000 }}
          text="Más información"
          tooltipText={
            <div aria-live="polite">
              <div className="flex items-center">
                <InfoIcon className="text-xs text-neutral-400" />
                <p className="text-sm ml-1">Texto de indicación</p>
              </div>
              <p className="text-neutral-400 text-xs font-normal my-1">
                Es el texto que indica al usuario la acción que debe realizar. Lo encuentras como &ldquo;Selecciona una
                opción&rdquo; por defecto, pero lo puedes modificar para hacer la indicación más especifica.
                <span aria-hidden="true"> Ej:</span>
              </p>
              <img
                src={DropdownIndicationExample}
                alt={
                  'Ejemplo con la etiqueta "Ciudad donde vives" y un texto de indicación modificado a "Selecciona una ciudad", en lugar de "Selecciona una opción".'
                }
                className="w-full"
              />
            </div>
          }
        >
          <InfoIcon className="text-2xl md:text-lg text-black" />
        </IconButton>
      </div>

      <WhenFieldChanges field={`${baseName}.has_indication_text`} set={`${baseName}.indication_text`} to={null} />
      {hasIndicationText && (
        <TextField
          name={`${baseName}.indication_text`}
          margin="dense"
          size="2xl"
          validate={validations.required()}
          placeholder="Escribe el texto de indicación"
        />
      )}
    </div>
  )
}

export default DropdownIndicationText

DropdownIndicationText.propTypes = {
  baseName: PropTypes.string
}
