import PropTypes from 'prop-types'
import React from 'react'
import { EmailPhoneApplicantField } from '../question_config_fields/EmailPhoneApplicantField'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import { EmailPhoneApplicantCitizenField } from '../question_fields/EmailPhoneApplicantCitizenField'
import Alert from '../../molecules/Alert'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const Form = props => <EmailPhoneApplicantField baseName={props.baseName} />

const Template = props => (
  <div className="grid md:grid-cols-2 gap-4">
    <div>
      <EmailPhoneApplicantCitizenField payload={props.question.payload} name={props.name} disabled />
    </div>
    <Alert type="info" border>
      Se enviará al ciudadano un código de validación a su correo o teléfono para asegurar que sea el correcto. El
      ciudadano deberá ingresar el código antes de enviar el formulario.
    </Alert>
  </div>
)

const Answer = ({ answer }) => {
  return <BoldLabelAndContent label={answer.contact_medium_label} content={answer.value} el="li" />
}

const TaskResponse = ({ answer }) => {
  return <BoldLabelAndContent label={`${answer.contact_medium_label}:`} content={answer.value} className="mb-0" />
}

const EmailPhoneApplicantQuestion = {
  selectOption: {
    value: 'email_phone_applicant',
    label: 'Correo o teléfono de contacto',
    icon: <PhoneAndroidIcon fontSize="small" />
  },
  Form,
  Template,
  Answer,
  TaskResponse
}

export default EmailPhoneApplicantQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}

Answer.propTypes = {
  answer: PropTypes.object
}

TaskResponse.propTypes = {
  answer: PropTypes.object
}
