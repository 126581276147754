import PropTypes from 'prop-types'
import { getLabelResult } from '../../../configs/quickSearchesConfig'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { HtmlField } from '../../molecules/fields/HtmlField'
import PreviewModal from './PreviewModal'
import { Button } from '../../atoms/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import IconButton from '@material-ui/core/IconButton'
import FormSection from '../../molecules/FormSection'
import useToggle from '../../../hooks/useToggle'
import SpecialCodesModal from './SpecialCodesModal'
import { TextButton } from '../../molecules/buttons/TextButton'
import Tooltip from '../../atoms/Tooltip'

const canDelete = index => index >= 2
const isMaxLength = length => length === 27

const FooterResult = ({ fields, index }) => (
  <div className="bg-white flex justify-end">
    <PreviewModal index={index} result={fields.value[index]} />
    {canDelete(index) && (
      <div className="flex">
        <Tooltip title="Eliminar">
          <IconButton type="button" color="primary" classes={{ root: 'p-0' }} onClick={() => fields.remove(index)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Button
          type="button"
          variant="text"
          size="xs"
          className="mr-4"
          onClick={() => fields.remove(index)}
          classes={{ root: 'px-0' }}
        >
          Borrar
        </Button>
      </div>
    )}
  </div>
)

const ResultsSection = () => {
  const { fields } = useFieldArray('results')
  const [specialCodesModal, setSpecialCodesModal] = useToggle()

  return (
    <FormSection title="Resultados">
      <p className="mb-6">Por cada resultado, define lo siguiente:</p>
      <ul className="list-disc ml-4 mb-8">
        <li>
          Escribe un <strong className="font-bold">texto corto que anunciará el resultado</strong> de la consulta. Te
          recomendamos usar Sí/No al inicio de la oración.
        </li>
        <li className="ml-8 mb-6">
          <strong className="font-bold">Ej.: </strong> Sí te encuentras inscrito en el padrón / No te encuentras
          inscrito en el padrón
        </li>
        <li>
          Luego, escribe en la <strong className="font-bold">información adicional</strong> los beneficios o
          características referentes al resultado. Para los resultados {'"'}A{'"'} en adelante, puedes utilizar las
          variables del excel ($1, $2, etc) en caso quieras brindar información específica.
        </li>
        <li className="ml-8 mb-6">
          <strong>Ej.: </strong>Has sido beneficiado con un bono de <strong className="font-bold">$1</strong>, que te
          será entregado el <strong className="font-bold">$2 </strong>(Has sido beneficiado con el bono de{' '}
          <strong>S/ 300 </strong>, que te será entregado el <strong className="font-bold">02/12/21</strong>).
        </li>
        <li>
          Si tus resultados contienen <strong className="font-bold">montos de dinero</strong>, te recomendamos usar los{' '}
          <TextButton onClick={setSpecialCodesModal}>códigos especiales</TextButton>.
        </li>
      </ul>
      {fields.map((name, index) => (
        <div key={index} className="mb-6">
          <div className="bg-neutral-300">
            <div className="p-8">
              <p className="text-2xl font-bold mb-2 flex items-end">
                Resultado: {getLabelResult(index)}
                {index < 2 && <span className="font-normal text-sm mb-1 ml-1">(obligatorio)</span>}
              </p>
              <TextField
                name={`${name}.title`}
                label="Texto corto que anunciará el resultado"
                size="full"
                validate={validations.required()}
              />
              <HtmlField name={`${name}.hint`} label="Información adicional" validate={validations.required()} />
            </div>
          </div>
          <FooterResult fields={fields} index={index} />
        </div>
      ))}
      <div className="flex gap-6">
        <Button
          onClick={() => fields.push()}
          variant="secondary"
          size="full"
          startIcon={<AddIcon />}
          className="w-full md:max-w-56"
          disabled={isMaxLength(fields.length)}
        >
          Agregar resultado
        </Button>
      </div>
      {specialCodesModal && <SpecialCodesModal onClose={setSpecialCodesModal} />}
    </FormSection>
  )
}

export default ResultsSection

FooterResult.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
