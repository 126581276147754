import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'

const config = {
  last_area_in_usertype: {
    title: 'No es posible eliminar esta área',
    content: (procedureManagementName, name) => (
      <>
        Esta área es la única asignada al tipo de usuario <strong>{name}</strong> en el trámite{' '}
        <strong>{procedureManagementName}</strong>. Para eliminarla,{' '}
        <strong>primero debes asignar otra área para el tipo de usuario {name}</strong>.
      </>
    ),
    button: 'Reasignar áreas del trámite'
  },
  last_user_in_usertype: {
    title: 'No es posible eliminar este usuario',
    content: (procedureManagementName, name) => (
      <>
        Este usuario es el único asignado como <strong>{name}</strong> en el trámite{' '}
        <strong>{procedureManagementName}</strong>. Para eliminarlo,{' '}
        <strong>primero debes asignar otro usuario como {name}</strong>.
      </>
    ),
    button: 'Reasignar usuarios del trámite'
  },
  change_area_to_user: {
    title: 'No es posible cambiar de área a este usuario',
    content: (procedureManagementName, name) => (
      <>
        Este usuario es el único asignado como <strong>{name}</strong> en el trámite{' '}
        <strong>{procedureManagementName}</strong>. Para cambiar su área,{' '}
        <strong>primero debes asignar otro usuario como {name}</strong>.
      </>
    ),
    button: 'Reasignar usuarios del trámite'
  }
}
const DialogErrorMessage = ({ onClose, params }) => {
  if (!params) return null

  const { title, content, button } = config[params.type]

  return (
    <ConfirmDialog
      open={Boolean(params)}
      onClose={onClose}
      title={title}
      primaryConfirmBtn
      confirmLabel={button}
      rightButtonProps={{
        el: Link,
        to: `/gestiones-de-tramites-habilitadas/${params?.data.enabled_procedure_management_id}/areas-y-usuarios`
      }}
    >
      <p className="text-sm">{content(params?.data.procedure_management_name, params?.data.name)}</p>
    </ConfirmDialog>
  )
}

export default DialogErrorMessage

DialogErrorMessage.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.object
}
