import React from 'react'
import useFilters from '../../../../hooks/useFilters'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import QuickSearchTable from '../../../organisms/quick_searches/QuickSearchTable'
import PageHeading from '../../../molecules/PageHeading'
import AddIcon from '@material-ui/icons/Add'
import useUserPermission from '../../../../hooks/useUserPermission'
import { getPageDescription } from '../../../../configs/quickSearchesConfig'
import AllInstitutionsBranchOfficeFilter from '../../../organisms/filters/AllInstitutionsBranchOfficeFilter'
import QuickSearchAlert from '../../../organisms/quick_searches/QuickSearchAlert'
import { useUrlActionRecord } from '../../../../hooks/useUrlActionRecord'
import { BoxTable } from '../../../molecules/BoxTable'
import QuickSearchFilter from '../../../organisms/quick_searches/QuickSearchFilter'

const QuickSearch = () => {
  const queryClient = useQueryClient()
  const { handleAction } = useUrlActionRecord({ type: 'quick_search' })
  const {
    initialFilters,
    results: quickSearch,
    onFilter
  } = useFilters(`/admin/v1/quick_searches.json`, { sort: 'desc' })

  const { canCreate } = useUserPermission('quickSearch')
  const handleDestroy = async quickSearch => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la consulta rápida ${quickSearch.name}?`)) {
      try {
        if (quickSearch.quick_search_queries > 0) {
          handleAction('deleted', 'error')
          return false
        } else {
          await axios.delete(`/admin/v1/quick_searches/${quickSearch.id}.json`)
          queryClient.invalidateQueries([`/admin/v1/quick_searches.json`])
          handleAction('deleted', 'success')
        }
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }

  const generalPermissions = useUserPermission()
  const pageDescription = getPageDescription(generalPermissions)

  return (
    <>
      <QuickSearchAlert />
      <PageHeading
        title={<span className="flex items-center">Consultas rápidas</span>}
        pageDescription={pageDescription}
        button={canCreate && { link: '/consultas-rapidas/crear', name: 'Crear consulta rápida', icon: <AddIcon /> }}
        linksSection={
          <a
            className="flex"
            href="https://guias.servicios.gob.pe/facilita/formularios-consultas-rapidas/index"
            target="_blank"
            rel="noreferrer"
          >
            <p className="font-bold mx-auto md:ml-0">¿Qué es una consulta rápida?</p>
          </a>
        }
        filterSection={<AllInstitutionsBranchOfficeFilter byType="by_quick_search" />}
      />
      <BoxTable
        filters={<QuickSearchFilter />}
        onFilter={onFilter}
        initialFilters={initialFilters}
        values={quickSearch}
        displayTotal
        displaySort
        displayHeader={false}
        accordionHasBackground={false}
      >
        <QuickSearchTable quickSearches={quickSearch.data} onDestroy={handleDestroy} />
      </BoxTable>
    </>
  )
}
export default QuickSearch
