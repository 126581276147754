import useInvalidateQueries from './useInvalidateQueries'
import { urlToJson } from '../utils/helpers'
import useCrud from './useCrud'

export const urlBaseForm = `/admin/v1/forms`

const useForm = () => {
  const { get: getForms } = useCrud(urlBaseForm)

  const { get: getAllFormIds } = useCrud(`${urlBaseForm}/all_forms_ids`)

  const { get: getAllForm } = useCrud(`${urlBaseForm}/all_forms`)

  const invalidateQueries = useInvalidateQueries()

  const invalidateForms = () => invalidateQueries([urlToJson(urlBaseForm)])

  return {
    invalidateForms,
    getForms,
    getAllFormIds,
    getAllForm
  }
}
export default useForm
