import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import Alert from '../../molecules/Alert'
import QuestionOptionsSection from './QuestionOptionsSection'

const HourField = ({ baseName, validate }) => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <span>
        <TextField
          label="Etiqueta de la pregunta"
          margin="none"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validate}
          errorClassName="relative inset-0"
          initialValue=""
        />
        <QuestionOptionsSection baseName={baseName} margin="none" size="full" />
      </span>
      <Alert type="info">El usuario verá un selector de horas y minutos.</Alert>
    </div>
  )
}

export default HourField

HourField.propTypes = {
  baseName: PropTypes.string,
  validate: PropTypes.func
}
