import React from 'react'
import PropTypes from 'prop-types'
import CustomChart from './CustomChart'
import useStatisticChart from '../../../../hooks/useStatisticChart'

const InstitutionsChart = ({ results }) => {
  const headerNames = {
    institutions: 'Entidades'
  }
  const { chartData, chartTime } = useStatisticChart(results, ['Años', 'Entidades registradas'], headerNames)

  return (
    <CustomChart
      chartData={chartData}
      titleTime={chartTime}
      titleOptions="Cantidad de entidades"
      title="Entidades registradas"
      description="Total, por tipo de entidad, con formularios publicados."
    />
  )
}

export default InstitutionsChart

InstitutionsChart.propTypes = {
  results: PropTypes.object.isRequired
}
