import React, { Fragment } from 'react'
import Alert from '../../molecules/Alert'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Link from '@material-ui/core/Link'
import ScrollToTop from '../../molecules/ScrollToTop'
import ActionAlert from '../../molecules/ActionAlert'

const actionTypes = [
  { type: 'form_deleted_success', status: 'success', content: 'Formulario eliminado.' },
  {
    type: 'form_deleted_error',
    status: 'error',
    content: 'No se puede eliminar un formulario con respuestas, solo puedes despublicarlo.'
  }
]

const FormAlert = () => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)
  return (
    <Fragment>
      {urlSearchParams.action === 'user_saved' && (
        <Alert type="success" className="mt-10">
          Cambios guardados exitosamente
        </Alert>
      )}
      {urlSearchParams.migrated && (
        <Alert type="success" className="md:mt-10" showClose>
          <ScrollToTop />
          <span className="font-bold">
            Los usuarios, áreas y servicios fueron asignados con éxito a la {urlSearchParams.migrated}.
          </span>
          <br />
          <span className="font-normal">Puedes modificar la asignación individual de los usuarios desde</span>
          <Link component={RouterLink} to="/areas-y-usuarios" className="font-medium ml-2">
            Áreas y usuarios
          </Link>
          .
        </Alert>
      )}
      {actionTypes.map(actionType => (
        <ActionAlert
          key={actionType.type}
          type={actionType.type}
          status={actionType.status}
          content={actionType.content}
        />
      ))}
    </Fragment>
  )
}

export default FormAlert
