import PropTypes from 'prop-types'
import React from 'react'
import BreadcrumbsMui from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, Route, Switch } from 'react-router-dom'
import Container from '../atoms/Container'
import useUserPermission from '../../hooks/useUserPermission'
import clsx from 'clsx'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import useMediaQuery from '../../hooks/useMediaQuery'

const defaultInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/formularios',
    label: 'Mis formularios'
  }
]

const defaultUserAndAreasInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/areas-y-usuarios',
    label: 'Áreas y usuarios'
  }
]

const defaultTemplatesInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/plantillas',
    label: 'Plantillas'
  }
]

const defaultFeedbacksInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/feedbacks',
    label: 'Feedbacks'
  }
]

const defaultQuickSearchInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/consultas-rapidas',
    label: 'Consultas rápidas'
  }
]

const defaultOnBoardingInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/centro-de-ayuda',
    label: 'Centro de ayuda'
  }
]
const defaultProcedureManagementsInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/gestiones-de-tramites',
    label: 'Gestión de trámites'
  }
]

const defaultEnabledProcedureManagementInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/gestiones-de-tramites-habilitadas',
    label: 'Gestión de trámites'
  }
]

const defaultEnabledProcedureManagementsInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/gestiones-de-tramites-habilitadas',
    label: 'Gestiones de trámites habilitadas'
  }
]

const defaultNotificationInitialRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/notificaciones',
    label: 'Notificaciones'
  }
]

const defaultInstitutionRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/entidades',
    label: 'Entidades'
  }
]

const defaultUserRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/areas-y-usuarios',
    label: 'Usuarios'
  }
]

const defaultTranslationsRoutes = [
  {
    route: '/',
    label: 'Inicio'
  },
  {
    route: '/traducciones',
    label: 'Traducciones'
  }
]

export const Breadcrumb = ({ data = {}, className }) => {
  const { canAccess } = useUserPermission('breadcrumb')

  const allRoutes = [
    {
      mainRoute: '/formularios/:form_id/respuestas/:answer_id',
      payload: [
        ...defaultInitialRoutes,
        {
          route: `/formularios/${data.id}/respuestas`,
          label: `Respuestas de ${data.name}`
        },
        {
          route: null,
          label: 'Respuesta'
        }
      ]
    },
    {
      mainRoute: '/formularios/:id/vista-previa',
      payload: [
        ...defaultInitialRoutes,
        {
          route: `/formularios/${data.id}/editar`,
          label: data.name
        },
        {
          route: null,
          label: 'Vista previa'
        }
      ]
    },
    {
      mainRoute: '/formularios/crear',
      payload: [
        ...defaultInitialRoutes,
        {
          route: null,
          label: 'Crear formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/crear/configuracion',
      payload: [
        ...defaultInitialRoutes,
        {
          route: null,
          label: 'Crear formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/crear/plantilla/:id',
      payload: [
        ...defaultInitialRoutes,
        {
          route: null,
          label: 'Crear formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/:form_id/respuestas',
      payload: [
        ...defaultInitialRoutes,
        {
          route: null,
          label: `Respuestas de ${data.name}`
        }
      ]
    },
    {
      mainRoute: '/formularios/:form_id/editar',
      payload: [
        ...defaultInitialRoutes,
        {
          route: null,
          label: 'Editar formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/:id/traducciones',
      payload: [
        ...defaultInitialRoutes,
        {
          route: `/formularios/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: null,
          label: 'Agregar idiomas adicionales para formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/:id/crear-traduccion',
      payload: [
        ...defaultInitialRoutes,
        {
          route: `/formularios/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: `/formularios/${data.id}/traducciones`,
          label: `Agregar idiomas adicionales para formulario`
        },
        {
          route: null,
          label: 'Traducción de formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/:id/editar-traduccion',
      payload: [
        ...defaultInitialRoutes,
        {
          route: `/formularios/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: `/formularios/${data.id}/traducciones`,
          label: `Agregar idiomas adicionales para formulario`
        },
        {
          route: null,
          label: 'Traducción de formulario'
        }
      ]
    },
    {
      mainRoute: '/formularios/:form_id/exportaciones',
      payload: [
        ...defaultInitialRoutes,
        {
          route: `/formularios/${data.id}/respuestas`,
          label: `Respuestas de ${data.name}`
        },
        {
          route: null,
          label: 'Historial de exportaciones'
        }
      ]
    },
    {
      mainRoute: '/usuarios/:user_id/editar',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Editar Usuario'
        }
      ]
    },
    {
      mainRoute: '/usuarios/crear',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Crear Usuario'
        }
      ]
    },
    {
      mainRoute: '/usuarios/detalles-desde-gobpe',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Crear Usuario'
        }
      ]
    },
    {
      mainRoute: '/usuarios/editar-desde-gobpe',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Crear Usuario'
        }
      ]
    },
    {
      mainRoute: '/usuarios/detalles-desde-facilita',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Crear Usuario'
        }
      ]
    },
    {
      mainRoute: '/usuarios/validar',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Crear Usuario'
        }
      ]
    },
    {
      mainRoute: '/plantillas/crear',
      payload: [
        ...defaultTemplatesInitialRoutes,
        {
          route: null,
          label: 'Crear Plantilla'
        }
      ]
    },
    {
      mainRoute: '/plantillas/:template_id/vista-previa',
      payload: [
        ...defaultTemplatesInitialRoutes,
        {
          route: null,
          label: 'Crear Plantilla'
        }
      ]
    },
    {
      mainRoute: '/plantillas/:template_id/editar',
      payload: [
        ...defaultTemplatesInitialRoutes,
        {
          route: null,
          label: 'Editar Plantilla'
        }
      ]
    },
    {
      mainRoute: '/feedbacks/exportaciones',
      payload: [
        ...defaultFeedbacksInitialRoutes,
        {
          route: null,
          label: 'Historial de exportaciones'
        }
      ]
    },
    {
      mainRoute: '/consultas-rapidas/crear',
      payload: [
        ...defaultQuickSearchInitialRoutes,
        {
          route: null,
          label: 'Crear consulta'
        }
      ]
    },
    {
      mainRoute: '/consultas-rapidas/:id/editar',
      payload: [
        ...defaultQuickSearchInitialRoutes,
        {
          route: null,
          label: 'Editar consulta'
        }
      ]
    },
    {
      mainRoute: '/consultas-rapidas/:id/resultados',
      payload: [
        ...defaultQuickSearchInitialRoutes,
        {
          route: null,
          label: 'Editar consulta'
        }
      ]
    },
    {
      mainRoute: '/consultas-rapidas/:id/vista-previa',
      payload: [
        ...defaultQuickSearchInitialRoutes,
        {
          route: null,
          label: 'Vista previa'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/articulo-tutorial/crear',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Crear artículo de tutorial'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/articulo-tutorial/:id/editar',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Editar artículo de tutorial'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/categorias',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Categorías de tutoriales'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/tutoriales',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Tutoriales'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/actualizaciones',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Actualizaciones'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/articulo-tutorial/:id/vista-previa',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Vista previa'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/articulo-actualizacion/:id/vista-previa',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: null,
          label: 'Vista previa'
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/articulo-actualizacion/:id',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: '/centro-de-ayuda/actualizaciones',
          label: `Actualizaciones`
        },
        {
          route: null,
          label: `${data.name}`
        }
      ]
    },
    {
      mainRoute: '/centro-de-ayuda/articulo-tutorial/:id',
      payload: [
        ...defaultOnBoardingInitialRoutes,
        {
          route: '/centro-de-ayuda/tutoriales',
          label: `Tutoriales`
        },
        {
          route: null,
          label: `${data.name}`
        }
      ]
    },
    {
      mainRoute: '/areas/crear',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Crear área'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/crear',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: null,
          label: 'Crear gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/areas/:area_id/editar',
      payload: [
        ...defaultUserAndAreasInitialRoutes,
        {
          route: null,
          label: 'Editar área'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/editar',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: null,
          label: 'Editar gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/formulario-inicial',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: null,
          label: 'Editar gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/tipos-de-usuarios-tareas-conexiones',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: null,
          label: 'Crear gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/vista-previa',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: `/gestiones-de-tramites/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: null,
          label: 'Vista previa'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/traducciones',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: `/gestiones-de-tramites/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: null,
          label: 'Agregar idiomas adicionales para formulario'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/formulario/:formId/editar-traduccion',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: `/gestiones-de-tramites/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: `/gestiones-de-tramites/${data.id}/traducciones`,
          label: 'Agregar idiomas adicionales para formulario'
        },
        {
          route: null,
          label: 'Traducir formulario'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites/:id/formulario/:formId/crear-traduccion',
      payload: [
        ...defaultProcedureManagementsInitialRoutes,
        {
          route: `/gestiones-de-tramites/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: `/gestiones-de-tramites/${data.id}/traducciones`,
          label: 'Agregar idiomas adicionales para formulario'
        },
        {
          route: null,
          label: 'Traducir formulario'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/crear',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: null,
          label: 'Habilitar gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/crear/:id',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: null,
          label: 'Habilitar gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:id/editar',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: null,
          label: 'Habilitar gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:id/areas-y-usuarios',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: null,
          label: 'Habilitar gestión de trámite'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:id/vista-previa',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: `/gestiones-de-tramites-habilitadas/${data.id}/editar`,
          label: `${data.name}`
        },
        {
          route: null,
          label: 'Vista previa'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:id/solicitudes',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: null,
          label: `${data.name}`
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:id/exportaciones',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: `/gestiones-de-tramites-habilitadas/${data.id}/solicitudes`,
          label: `Respuestas de ${data.name}`
        },
        {
          route: null,
          label: 'Historial de exportaciones'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/exportaciones',
      payload: [
        ...defaultEnabledProcedureManagementsInitialRoutes,
        {
          route: null,
          label: 'Historial de exportaciones'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:enabled_procedure_id/solicitudes/:id',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: `/gestiones-de-tramites-habilitadas/${data.enabled_procedure_management_id}/solicitudes`,
          label: `${data.name}`
        },
        {
          route: null,
          label: 'Detalle de solicitud'
        }
      ]
    },
    {
      mainRoute: '/gestiones-de-tramites-habilitadas/:id/entrada-manual',
      payload: [
        ...defaultEnabledProcedureManagementInitialRoutes,
        {
          route: `/gestiones-de-tramites-habilitadas/${data.id}/solicitudes`,
          label: `${data.name}`
        },
        {
          route: null,
          label: 'Ingresar solicitud manual'
        }
      ]
    },
    {
      mainRoute: '/notificaciones/crear',
      payload: [
        ...defaultNotificationInitialRoutes,
        {
          route: null,
          label: 'Enviar notificación'
        }
      ]
    },
    {
      mainRoute: '/notificaciones/:id/editar',
      payload: [
        ...defaultNotificationInitialRoutes,
        {
          route: null,
          label: 'Editar notificación'
        }
      ]
    },
    {
      mainRoute: '/entidades/:id/detalles',
      payload: [
        ...defaultInstitutionRoutes,
        {
          route: null,
          label: 'Detalle de entidad'
        }
      ]
    },
    {
      mainRoute: '/usuarios/:id/detalles',
      payload: [
        ...defaultUserRoutes,
        {
          route: null,
          label: 'Detalle de usuario'
        }
      ]
    },
    {
      mainRoute: '/mi-cuenta',
      payload: [
        {
          route: '/',
          label: 'Inicio'
        },
        {
          route: null,
          label: 'Mi cuenta'
        }
      ]
    },
    {
      mainRoute: '/traducciones/crear',
      payload: [
        ...defaultTranslationsRoutes,
        {
          route: null,
          label: 'Agregar idioma'
        }
      ]
    },
    {
      mainRoute: '/traducciones/carga-masiva',
      payload: [
        ...defaultTranslationsRoutes,
        {
          route: null,
          label: 'Subir carga masiva'
        }
      ]
    },
    {
      mainRoute: '/traducciones/:id/editar',
      payload: [
        ...defaultTranslationsRoutes,
        {
          route: null,
          label: 'Agregar idioma'
        }
      ]
    }
  ]

  const matchTabletSize = useMediaQuery('md')

  const getBreadcrumbItems = (route, matchTabletSize) => {
    if (matchTabletSize) return route.payload
    const accessibleIndex = route.payload
      .slice(0, -1)
      .reduceRight((acc, _, i) => (acc === -1 && canAccess(route.mainRoute, i) ? i : acc), -1)
    return accessibleIndex >= 0 ? [route.payload[accessibleIndex]] : []
  }

  return (
    <div className={clsx('bg-neutral-200 border-b-2 border-solid border-neutral-300', className)}>
      <Container>
        <div className="py-1 mx-0">
          <Switch>
            {allRoutes.map((route, key) => {
              const breadcrumbItems = getBreadcrumbItems(route, matchTabletSize)
              return (
                <Route exact path={route.mainRoute} key={key}>
                  <BreadcrumbsMui
                    className="text-sm"
                    separator={
                      <NavigateNextIcon fontSize="small" className="hidden md:inline-block text-blue-700 mx-1" />
                    }
                  >
                    {breadcrumbItems.map((item, index) => {
                      const canUserAccess = canAccess(route.mainRoute, index)
                      const itemClass = clsx({
                        'hidden md:block': matchTabletSize,
                        'font-bold': canUserAccess,
                        'text-gray-700': canUserAccess && !item.route
                      })

                      if (!canUserAccess) {
                        return (
                          <span key={`${item.label}_${index}_disabled`} className="text-gray-500">
                            {item.label}
                          </span>
                        )
                      }
                      return (
                        <Link key={item.route} component={RouterLink} to={item.route} className={itemClass}>
                          <NavigateBeforeIcon fontSize="small" className="md:hidden text-blue-700 mx-1" />
                          {item.label}
                        </Link>
                      )
                    })}
                  </BreadcrumbsMui>
                </Route>
              )
            })}
          </Switch>
        </div>
      </Container>
    </div>
  )
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
}
