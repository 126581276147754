import React from 'react'
import clsx from 'clsx'
import Container from '../../../atoms/Container'
import PageHeading from '../../../molecules/PageHeading'
import useFilters from '../../../../hooks/useFilters'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { urlBase } from '../../../../hooks/useTutorial'
import CardDescription from '../../../organisms/onboarding/CardDescription'
import ArticleFilter from '../../../organisms/onboarding/ArticleFilter'
import Accordion from '../../../molecules/Accordion'
import { urlToJson } from '../../../../utils/helpers'
import { BoxTable } from '../../../molecules/BoxTable'

const Tutorials = () => {
  const {
    results: tutorialsByCategoria,
    initialFilters,
    onFilter
  } = useFilters(urlToJson(`${urlBase}/tutorials_by_category`), {})

  return (
    <>
      <PageNavigation />
      <Container>
        <PageHeading title="Tutoriales" pageDescription="Aprende cómo usar Facilita" />
        <BoxTable
          values={tutorialsByCategoria}
          onFilter={onFilter}
          initialFilters={initialFilters}
          searchPlaceholder="Buscar por nombre"
          searchFieldName="search_t"
          paginationLabel="page_t"
          displayHeader={false}
          displayTotal
          filters={<ArticleFilter hasCategory suffix="_t" categoryAlign="horizontal" />}
          accordionHasBackground={false}
          className="-mt-2"
        >
          <div className="-mt-2 pb-2">
            {tutorialsByCategoria?.data.map((tutorialByCategoria, index) => (
              <div key={`tcat_${index}`}>
                <Accordion title={<h4 className={clsx('text-lg font-bold m-0')}>{tutorialByCategoria.category}</h4>}>
                  <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3 w-full">
                    {tutorialByCategoria.tutorials.map(tutorial => (
                      <CardDescription
                        key={tutorial.id}
                        to={`/centro-de-ayuda/articulo-tutorial/${tutorial.id}`}
                        name={tutorial.name}
                        description={tutorial.description}
                        descriptionLines={2}
                      />
                    ))}
                  </div>
                </Accordion>
              </div>
            ))}
          </div>
        </BoxTable>
      </Container>
    </>
  )
}
export default Tutorials
