import React from 'react'
import PropTypes from 'prop-types'
import CustomChart from './CustomChart'
import useStatisticChart from '../../../../hooks/useStatisticChart'

const ReceivedRequestsChart = ({ results }) => {
  const { chartData, chartTime } = useStatisticChart(results, ['Años', 'Respuestas recibidas'])

  return (
    <CustomChart
      chartData={chartData}
      titleTime={chartTime}
      titleOptions="Cantidad de solicitudes por estado"
      title="Estados de solicitud"
      description="Cantidad de solicitudes por estado."
    />
  )
}

export default ReceivedRequestsChart

ReceivedRequestsChart.propTypes = {
  results: PropTypes.object.isRequired
}
