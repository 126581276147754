import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { TruncatedTextWithTooltip } from '../../molecules/TruncatedTextWithTooltip'
import UserInfoTable from './UserInfoTable'
import useUserPermission from '../../../hooks/useUserPermission'
import FormsCopyButton from './FormsCopyButton'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import TranslateButton from '../../molecules/buttons/TranslateButton'

const FormsTable = ({ forms = [], onDestroy }) => {
  const { canSeeAnswer, canEdit, canDelete, canCreate } = useUserPermission('form')
  const allowedAnswers = form => (
    <Td>
      {canSeeAnswer(form) ? (
        <span>{form.answers} rptas.</span>
      ) : (
        <Link to={`/formularios/${form.id}/respuestas`}>{form.answers} rptas.</Link>
      )}
    </Td>
  )

  return (
    <div>
      <Table
        className="table-fixed md:w-full"
        values={forms}
        head={
          <Tr body={false}>
            <Th className="w-11">ID</Th>
            <Th className="md:w-80">Nombre</Th>
            <Th className="md:w-40">Clase de formulario</Th>
            <Th className="min-w-45 md:w-45">Creación</Th>
            <Th className="min-w-45 md:w-45">Actualización</Th>
            <Th className="md:w-27.5">Publicación</Th>
            <Th className="md:w-28">Respuestas</Th>
            {canCreate && (
              <Th className="min-w-48 w-48">
                <span className="ml-4">Acciones</span>{' '}
              </Th>
            )}
          </Tr>
        }
        body={
          <>
            {forms.map(form => (
              <Tr key={form.id}>
                <Td>{form.id}</Td>
                <Td>
                  <TruncatedTextWithTooltip text={form.name}>
                    {props => (
                      <Link to={`/formularios/${form.id}/vista-previa`} {...props}>
                        {form.name}
                      </Link>
                    )}
                  </TruncatedTextWithTooltip>
                </Td>
                <Td>{form.type}</Td>
                <Td>
                  <UserInfoTable
                    id={form.created_by}
                    name={form.creator_name}
                    eliminatorName={form.creator_eliminator_name}
                    eliminatedName={form.eliminated_creator_name}
                    date={form.date_created_at}
                    hour={form.hour_created_at}
                    hasHour
                  />
                </Td>
                <Td>
                  <UserInfoTable
                    id={form.updated_by}
                    name={form.updater_name}
                    eliminatorName={form.updater_eliminator_name}
                    eliminatedName={form.eliminated_updater_name}
                    date={form.date_updated_at}
                    hour={form.hour_updated_at}
                    hasHour
                  />
                </Td>
                <Td>{form.published ? 'Publicado' : 'No publicado'}</Td>
                {allowedAnswers(form)}
                {canCreate && (
                  <Td>
                    <div>
                      {canEdit(form) && <EditButton to={`/formularios/${form.id}/editar`} />}
                      {canDelete(form) && <DeleteButton onClick={() => onDestroy(form)} />}
                    </div>
                    <div>
                      <TranslateButton to={`/formularios/${form.id}/traducciones`} />
                      <FormsCopyButton form={form} />
                    </div>
                  </Td>
                )}
              </Tr>
            ))}
          </>
        }
      />
    </div>
  )
}

export default FormsTable

FormsTable.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.object),
  onDestroy: PropTypes.func
}
