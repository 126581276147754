import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import FileField from '../../molecules/fields/FileField'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useField, useForm } from 'react-final-form'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'

const extensions = ['pdf']

const getServiceNameByType = serviceType => {
  const services = [
    { type: 'form', name: 'el formulario' },
    { type: 'quick_search', name: 'la consulta' }
  ]

  return services.find(service => service.type === serviceType)?.name
}

const PrivacyPolicyCreator = ({ exampleFileUrl, required = true, serviceType = 'form' }) => {
  const form = useForm()
  const { input } = useField('already_asked_submit_without_privacy_policy', { initialValue: false })

  const onConfirmSubmit = () => {
    form.submit()
    input.onChange(false)
  }

  const _exampleFileUrl = exampleFileUrl || '/Política de Privacidad 2024-1.docx'
  return (
    <>
      <legend id="privacy_policy" className="font-bold mb-2">
        Crear política de privacidad
      </legend>
      <ol className="list-decimal pl-0 list-inside">
        <li className="mb-0">
          Descarga el archivo de ejemplo de políticas de privacidad y modifícalo según la información que estás pidiendo
          en tu formulario. Guarda el archivo en formato PDF.
        </li>
        <Button
          el="a"
          href={_exampleFileUrl}
          target="_blank"
          size="full"
          className="py-2 mt-2 md:max-w-56"
          variant="secondary"
          startIcon={<GetAppIcon />}
        >
          Descargar ejemplo
        </Button>
        <li className="mt-8">
          Sube el archivo en formato PDF que has creado para este formulario.
          <FileField
            name="privacy_policy"
            className="mt-2"
            required={required}
            ariaLabel="Política de privacidad"
            extensions={extensions}
          />
          <ConfirmDialog
            title="Aún no has subido la Política de Privacidad"
            open={input.value}
            onConfirm={() => onConfirmSubmit()}
            onClose={() => input.onChange(false)}
            primaryConfirmBtn
          >
            <p>
              Puedes guardar tus avances, pero ten en cuenta que{' '}
              <strong>deberás subir la Política de Privacidad antes de publicar</strong>{' '}
              {getServiceNameByType(serviceType)}.
            </p>
          </ConfirmDialog>
        </li>
      </ol>
    </>
  )
}
export default PrivacyPolicyCreator

PrivacyPolicyCreator.propTypes = {
  exampleFileUrl: PropTypes.string,
  required: PropTypes.bool,
  serviceType: PropTypes.string
}
