import React from 'react'
import FooterLinks from '../../molecules/footer/FooterLinks'
import Container from '../../atoms/Container'
import FooterWrapper from '../../molecules/footer/FooterWrapper'

const InternalFooter = () => {
  const listGuides = [
    { href: 'https://guias.servicios.gob.pe/facilita/ayuda/index', text: 'Solicitar ayuda al equipo de Facilita Perú' },
    { href: 'https://guias.servicios.gob.pe/facilita', text: 'Ver todas las guías' },
    { href: 'https://www.youtube.com/@Gobpe', text: 'Ir al canal de Youtube' }
  ]
  const listPrivacy = [
    {
      href: 'https://www.gob.pe/institucion/pcm/informes-publicaciones/1991056-politica-de-privacidad-de-retroalimentacion-de-formularios-en-facilita',
      text: 'Política de privacidad de Facilita Perú'
    }
  ]

  return (
    <FooterWrapper>
      <Container className="py-12 px-6 md:px-0">
        <div className="container md:flex md:flex-row md:px-8 justify-between">
          <div className="mt-6 md:mt-0">
            <FooterLinks list={listGuides} />
          </div>
          <div className="mt-10 md:mt-0">
            <FooterLinks list={listPrivacy} />
          </div>
        </div>
      </Container>
    </FooterWrapper>
  )
}

export default InternalFooter
