import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import FormSection from '../../molecules/FormSection'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import { HtmlField } from '../../molecules/fields/HtmlField'
import FieldTitle from '../../molecules/FieldTitle'
import { fieldTitle } from '../../../configs/procedureManagementsConfig'
import Value from '../../molecules/Value'
import PaymentSetup from '../forms/PaymentSetup'
import { KeyQuestion } from './KeyQuestion'
import FileField from '../../molecules/fields/FileField'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'

export const InitialFormSetup = () => {
  return (
    <FormSection title="Información y configuración general">
      <TextField name="name" label="Nombre del formulario" margin="none" size="2xl" validate={validations.required()} />
      <FieldsSeparator />
      <HtmlField
        name="hint"
        label="Indicación general del formulario"
        placeholder="Ejemplo: La recepción de documentos es de lunes a viernes, de 8:00 a. m. a 5:00 p. m.; después de este horario, la solicitud se registrará al el día hábil siguiente. Ingresa la información requerida."
        hint="Es la información que se le dará al ciudadano para ayudarlo con el proceso del trámite."
        validate={validationsAdmin.isRichTextFieldEmpty()}
        maxContentLength={600}
      />
      <FieldsSeparator />
      <FieldTitle title={fieldTitle('it_costs').title} hint={fieldTitle('it_costs').hint} />
      <Value name="it_costs">{({ value: itCosts }) => itCosts && <PaymentSetup showPaymentMothds={false} />}</Value>
      <FieldsSeparator />
      <FieldTitle title={fieldTitle('show_auto_response').title} hint={fieldTitle('show_auto_response').hint} />
      <WhenFieldChanges field={`show_auto_response`} set={`auto_response_text`} to={null} />
      <Value name="show_auto_response">
        {({ value: hasAdditionalInformation }) =>
          hasAdditionalInformation && (
            <HtmlField
              name="auto_response_text"
              placeholder="Ejemplo: Tenga en cuenta que su solicitud será atendida en un plazo máximo de 5 días hábiles, a menos que se le pida una subsanación."
              validate={validationsAdmin.isRichTextFieldEmpty()}
            />
          )
        }
      </Value>
      <FieldsSeparator />
      <FieldTitle title={fieldTitle('has_key_question').title} hint={fieldTitle('has_key_question').hint} />
      <Value name="has_key_question">
        {({ value: hasQuestionKey }) =>
          hasQuestionKey && (
            <Value name="steps_attributes">
              {({ value: stepsAttributes }) => <KeyQuestion steps={stepsAttributes} />}
            </Value>
          )
        }
      </Value>
      <FieldsSeparator />
      <FieldTitle title={fieldTitle('sworn_declaration').title} hint={fieldTitle('sworn_declaration').hint} />
      <FieldsSeparator />
      <FieldTitle
        title={fieldTitle('privacy_policy_example_type').title}
        hint={fieldTitle('privacy_policy_example_type').hint}
      />
      <Value name="privacy_policy_example_type">
        {({ value: privacyPolicyExampleType }) =>
          privacyPolicyExampleType === 'modified' && (
            <>
              <p className="mt-8">Sube el archivo en formato .doc o .docx</p>
              <FileField
                name="privacy_policy"
                className="mt-2"
                extensions={['doc', 'docx']}
                required
                ariaLabel="Política de privacidad modificado"
              />
            </>
          )
        }
      </Value>
    </FormSection>
  )
}
