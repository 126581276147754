import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { useFieldArray } from 'react-final-form-arrays'
import TextField from '../../molecules/fields/TextField'
import { Button } from '../../atoms/Button'
import AddIcon from '@material-ui/icons/Add'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import { getBankOptions } from '../../../configs/paymentMethodConfigs'
import Value from '../../molecules/Value'
import TransferAccountReadonly from './TransferAccountReadonly'
import Error from '../../molecules/Error'

const TransferAccounts = ({ onSubmit, submitting }) => {
  const { fields } = useFieldArray('payment_transfer_accounts_attributes')
  const [state, setState] = useState({})
  const handleDelete = index => {
    if (window.confirm(`¿Estás seguro que desea eliminar la Cuenta ${index + 1}?`)) {
      if (fields.value[index]?.id) {
        fields.markForDestroy(index)
        onSubmit()
      } else {
        fields.remove(index)
      }
    }
  }
  return (
    <fieldset className="mb-12 border-t-2 border-neutral-200">
      <p className="my-4">
        Agrega las cuentas bancarias donde los ciudadanos podrán realizar transferencias y depósitos
      </p>
      {fields.map((name, index) => (
        <Fragment key={index}>
          <Value name={name}>
            {({ value: transferAccount }) =>
              transferAccount.id && state[name] !== 'edit' ? (
                <TransferAccountReadonly
                  transferAccount={transferAccount}
                  index={index + 1}
                  handleEdit={() => setState({ ...state, [name]: 'edit' })}
                  handleDelete={() => handleDelete(index)}
                  submitting={submitting}
                />
              ) : (
                <fieldset className="bg-neutral-200 p-4 md:p-8 mb-4 max-w-md" key={index}>
                  <div className="flex justify-between items-center font-bold">
                    <span>Cuenta {index + 1}</span>
                    <IconButton type="button" color="primary" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <SelectField
                    name={`${name}.bank`}
                    label="Entidad financiera"
                    options={getBankOptions}
                    size="full"
                    validate={validations.required()}
                  />
                  <TextField
                    name={`${name}.number`}
                    label="Número de cuenta"
                    size="full"
                    validate={validations.mix(validations.isNumber(), validations.required())}
                  />
                  <TextField
                    name={`${name}.cci_number`}
                    label="Código de cuenta interbancario (CCI)"
                    size="full"
                    validate={validations.mix(validations.isNumber(), validations.required())}
                  />
                  <TextField
                    name={`${name}.company_name`}
                    label="Razón social"
                    size="full"
                    validate={validations.required()}
                  />
                  <TextField
                    name={`${name}.ruc`}
                    label="Número de RUC"
                    size="full"
                    validate={validations.mix(
                      validations.minLength(11),
                      validations.maxLength(11),
                      validations.isNumber(),
                      validations.required()
                    )}
                  />
                  <div className="md:max-w-40 ml-auto">
                    <Button
                      variant="secondary"
                      size="full"
                      onClick={() => {
                        setState({ ...state, [name]: '' })
                        onSubmit()
                      }}
                      disabled={submitting}
                    >
                      Guardar
                    </Button>
                  </div>
                </fieldset>
              )
            }
          </Value>
        </Fragment>
      ))}

      <Error className="w-full text-base leading-6 my-2" name="payment_transfer_accounts" showInfoIcon />

      <div className="md:max-w-48">
        <Button startIcon={<AddIcon />} variant="secondary" onClick={() => fields.push()} size="full">
          Agregar cuenta
        </Button>
      </div>
    </fieldset>
  )
}

export default TransferAccounts

TransferAccounts.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool
}
