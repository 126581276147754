import PropTypes from 'prop-types'
import React from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import clsx from 'clsx'
import { Select, InputBase, MenuItem, Typography, ListSubheader, makeStyles } from '@material-ui/core'
import Error from '../Error'
import Hint from '../../atoms/Hint'
import { marginSizes } from '../../../configs/sizesConfig'
import { useCustomField } from '../../../hooks/useCustomField'
import useToggle from '../../../hooks/useToggle'

const sizeMap = {
  full: 'w-full',
  '5xl': 'sm:max-w-xl',
  '4xl': 'sm:max-w-lg',
  '3xl': 'sm:max-w-md',
  '2xl': 'sm:max-w-sm',
  xl: 'sm:max-w-xs',
  lg: 'sm:max-w-64',
  'w-60': 'sm:max-w-60',
  'w-24': 'sm:max-w-24'
}

const BootstrapInput = ({ color, ...props }) => {
  const colors = {
    default: 'border-gray-800 bg-white',
    blue: 'border-blue-600 bg-blue-400'
  }
  return (
    <InputBase
      classes={{
        root: clsx('block border-2 rounded-none w-full box-border', colors[color]),
        input: 'box-border flex items-center text-base focus:outline-none p-0 min-h-12'
      }}
      {...props}
    />
  )
}

const useSelectStyles = makeStyles(() => ({
  removePaddingRight: {
    '& .MuiSelect-select': {
      paddingRight: '0px'
    }
  },
  hideIconButton: {
    '& .MuiIconButton-root': {
      display: 'none'
    }
  }
}))

export const SelectInput = ({
  name,
  label,
  labelClassName,
  children,
  className,
  size = 'xl',
  parentClassName,
  native = false,
  hint,
  color = 'default',
  menuProps,
  onOpen,
  ...props
}) => {
  const classes = useSelectStyles()
  const [expanded, toggleExpanded] = useToggle()
  const handleToggleExpanded = event => {
    toggleExpanded()
    event && event()
  }
  return (
    <div className={clsx(parentClassName, 'w-full')}>
      {label && (
        <label
          htmlFor={`${name}-input`}
          id={`${name}-label`}
          className={labelClassName || 'block font-bold min-w-25 mb-2'}
        >
          {label}
        </label>
      )}
      {hint && <Hint hint={hint} name={name} />}
      <Select
        className={clsx(
          className,
          'text-base rounded',
          sizeMap[size],
          classes.removePaddingRight,
          classes.hideIconButton
        )}
        native={native}
        displayEmpty
        MenuProps={menuProps}
        labelId={`${name}-label`}
        SelectDisplayProps={{
          role: 'combobox',
          'aria-expanded': expanded
        }}
        onOpen={() => handleToggleExpanded(onOpen)}
        onClose={() => handleToggleExpanded()}
        {...props}
        input={<BootstrapInput id={`${name}-input`} inputProps={{ 'data-testid': `${name}-input` }} color={color} />}
      >
        {children}
      </Select>
    </div>
  )
}

const SelectField = ({
  options = [],
  label,
  labelClassName,
  className,
  parentClassName,
  size,
  name,
  validate,
  native,
  hint = '',
  disabled = false,
  containerClassName = '',
  errorClassName = '',
  margin = 'normal',
  emptyLabel = 'Selecciona una opción',
  hideError = false,
  color,
  onOpen,
  menuProps,
  ...props
}) => {
  const { input, gotError } = useCustomField(name, { validate, ...props })
  return (
    <div className={clsx(marginSizes[margin], containerClassName, 'relative js-field-container')}>
      <SelectInput
        name={name}
        label={label}
        labelClassName={labelClassName}
        className={clsx(
          className,
          gotError ? 'focus-within:border-red-500 border-red-500' : 'focus-within:border-yellow-500'
        )}
        parentClassName={parentClassName}
        native={native}
        size={size}
        hint={hint}
        disabled={disabled}
        color={color}
        {...input}
        onOpen={onOpen}
        menuProps={menuProps}
        onChange={e => {
          input.onChange(e.target.value)
          if (props.onChange) {
            props.onChange(e.target.value)
          }
          if (props.onChangeEvent) {
            props.onChangeEvent(e)
          }
        }}
      >
        <MenuItem className="text-base p-0" value="">
          <Typography className={clsx('text-base whitespace-normal leading-none pl-4 pr-6', emptyLabel && 'py-2')}>
            {emptyLabel}
          </Typography>
        </MenuItem>
        {options.map((o, index) =>
          o.groupLabel ? (
            <ListSubheader disableSticky key={index}>
              {o.groupLabel}
            </ListSubheader>
          ) : (
            <MenuItem key={index} value={o.value} className="p-0" id={`${name}-${index}`}>
              {o.icon && <ListItemIcon className="pl-4 -mr-4">{o.icon}</ListItemIcon>}
              <Typography
                component="div"
                className={clsx('text-base whitespace-normal pl-4 pr-8 py-2 w-full hover:bg-neutral-200', o.className)}
              >
                {o.label}
              </Typography>
            </MenuItem>
          )
        )}
      </SelectInput>
      {!hideError && <Error name={name} className={clsx('absolute -bottom-6', errorClassName)} />}
    </div>
  )
}

export default SelectField

SelectInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  menuProps: PropTypes.object,
  name: PropTypes.string,
  native: PropTypes.bool,
  parentClassName: PropTypes.string,
  size: PropTypes.string,
  onOpen: PropTypes.func
}

SelectField.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  emptyLabel: PropTypes.string,
  errorClassName: PropTypes.string,
  hideError: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  margin: PropTypes.string,
  menuProps: PropTypes.object,
  name: PropTypes.string,
  native: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeEvent: PropTypes.func,
  onOpen: PropTypes.func,
  options: PropTypes.array,
  parentClassName: PropTypes.string,
  size: PropTypes.string,
  validate: PropTypes.func
}

BootstrapInput.propTypes = {
  color: PropTypes.string
}
