import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Form } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { Button } from '../../atoms/Button'
import Container from '../../atoms/Container'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { getLabelDocumentType } from '../../../configs/quickSearchesConfig'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import BirthDateValidation from '../quick_searches/BirthDateValidation'
import ReCAPTCHAField from '../question_fields/ReCAPTCHAField'

const fetchQuery = (id, values) =>
  axios.get(`/v1/quick_searches/${id}/query.json`, { params: values }).then(res => res.data)

const CitizenQuickSearch = ({ quickSearch, setQuickResult }) => {
  const recaptchaRef = useRef(null)
  const { id } = useParams()
  const {
    privacy_policy: privacyPolicy,
    name,
    hint,
    informative_text: informativeText,
    document_type: documentType,
    other_document_label: otherDocumentLabel,
    logo,
    validation_type: validationType,
    filling_start_time: fillingStartTime
  } = quickSearch

  const handleSubmit = async values => {
    try {
      const recaptcha = await recaptchaRef.current.executeAsync()
      await fetchQuery(id, { ...values, recaptcha }).then(data => setQuickResult(data))
    } catch (error) {
      if (error.response.status === 403) {
        recaptchaRef.current.reset()
        return { birth_date_validation: error.response.data }
      }
    }
  }

  const isOtherDocument = documentType === 'other_document'
  const labelTypeDocument = getLabelDocumentType(documentType, otherDocumentLabel)

  return (
    <>
      <Container className="py-8 md:py-16 px-4 md:px-0">
        <main className="m-auto">
          <div className="flex-1 md:flex h-full">
            <div className={clsx('md:w-1/2 p-4 md:px-6 pb-0', logo?.url ? '' : 'grid items-center')}>
              <section className="md:mx-12" aria-labelledby="bienvenida-label">
                {logo?.url && (
                  <div
                    className="w-full md:w-100 h-30 mb-6 bg-contain bg-no-repeat"
                    style={{ backgroundImage: `url('${logo?.url}')` }}
                  />
                )}
                <h1 className="font-bold text-3xl md:text-4xl mb-4">{name}</h1>
                <p className="md:mb-4 font-bold">{hint}</p>
                <p className="md:mb-4" dangerouslySetInnerHTML={{ __html: informativeText }} />
              </section>
            </div>
            <Form onSubmit={handleSubmit} initialValues={{ filling_start_time: fillingStartTime }}>
              {({ handleSubmit, submitting }) => (
                <form
                  onSubmit={handleSubmit}
                  className="grid md:w-1/2 px-6 pt-8 md:pt-0 relative md:top-0 items-center justify-center"
                  aria-labelledby="consulta rapida"
                >
                  <div className="p-6 lg:p-8 lg:max-w-md bg-neutral-100 mb-10">
                    <TextField
                      name="document_number"
                      label={isOtherDocument ? labelTypeDocument : `Número de ${labelTypeDocument}`}
                      size="full"
                      labelClassName="text-lg"
                      validate={validations.mix(validations.required(), !isOtherDocument && validations.isNumber())}
                    />
                    {validationType === 'birth_date' && <BirthDateValidation />}
                    <ReCAPTCHAField name="recaptcha" ref={recaptchaRef} />
                    {privacyPolicy && (
                      <CheckboxField
                        name="accepts_privacy_policy"
                        validate={validations.required()}
                        margin="dense"
                        className="mb-6"
                        label={
                          <p>
                            Acepto{' '}
                            <a href={privacyPolicy.url} target="_blank" rel="noreferrer">
                              la política de privacidad
                            </a>
                          </p>
                        }
                      />
                    )}
                  </div>
                  <Button disabled={submitting} type="submit">
                    Consultar
                  </Button>
                </form>
              )}
            </Form>
          </div>
        </main>
      </Container>
    </>
  )
}
export default CitizenQuickSearch

CitizenQuickSearch.propTypes = {
  quickSearch: PropTypes.object,
  setQuickResult: PropTypes.func.isRequired
}
