import PropTypes from 'prop-types'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { isSuccessful } from '../../hooks/useQuery'

const initialState = {
  status: 'loading'
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'GOT_USER':
      return { user: action.payload, status: 'success' }
    case 'LOGOUT':
      return { status: 'no_user', user: { permission: [], role: 'logged_out' } }
    case 'FAILED':
      return { status: 'failed', user: { permission: [], role: 'logged_out' } }
    default:
      throw new Error()
  }
}

const AuthContext = createContext(null)
export const useAuth = () => useContext(AuthContext)

const Auth = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const logout = () => axios.delete(`/admin/v1/sessions.json`).then(() => dispatch({ type: 'LOGOUT' }))

  const getUser = () =>
    axios
      .get(`/admin/v1/users/me.json`)
      .then(res => dispatch({ type: 'GOT_USER', payload: res.data }))
      .catch(() => dispatch({ type: 'FAILED' }))

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (isSuccessful(state.status) && state?.user?.enabled === false) {
      logout()
    }
  }, [state])

  return (
    <AuthContext.Provider value={{ ...state, getUser, logout }}>
      {state.status !== 'loading' ? children : null}
    </AuthContext.Provider>
  )
}

export default Auth

Auth.propTypes = {
  children: PropTypes.node
}
