import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import * as validations from '../../../utils/validations'
import Error from '../../molecules/Error'
import Hint from '../../atoms/Hint'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import useFieldValue from '../../../hooks/useFieldValue'
import useTextValidation from '../../../hooks/useTextValidation'
import { useTranslation } from 'react-i18next'

const CheckboxesCitizenField = ({ name, payload, required, index, disabled = false, margin = 'normal' }) => {
  const translationText = useTextValidation()
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const limit = useFieldValue(name)
  const disableAlternativeUnchecked = alternative => {
    return (
      limit?.selected?.length === parseInt(payload.alternative_limits) && !limit?.selected?.includes(alternative?.label)
    )
  }
  const { t } = useTranslation(['form_field_options'])
  const otherLabel = t('radio_checkbox_button_other', { ns: 'form_field_options' })
  return (
    <div className="relative">
      <fieldset className={clsx(marginSizes[margin], 'js-field-container')}>
        <legend className="mb-2">
          <span className="font-bold">{label}</span>{' '}
          {payload.alternative_limits && `(máximo ${payload.alternative_limits} opciones)`}
        </legend>
        {payload?.hint && <Hint hint={payload.hint} name={name} />}
        {(payload?.alternatives || []).map(alternative => (
          <CheckboxField
            key={alternative?.label}
            name={`${name}.selected`}
            baseName={name}
            type={alternative?.type}
            margin="none"
            label={alternative?.type === 'other' ? otherLabel : alternative?.label}
            value={alternative?.label}
            disabled={disabled || disableAlternativeUnchecked(alternative)}
            validate={required && validations.requiredArray('Ingresa al menos una opción.', 1)}
            otherValidate={required && validations.required(translationText.required)}
            hideError
          />
        ))}
        <Error className="absolute -bottom-4" name={`${name}.selected`} />
      </fieldset>
    </div>
  )
}

export default CheckboxesCitizenField

CheckboxesCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
