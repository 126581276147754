import { getNextActiveIndex, getPrevActiveIndex } from './destroyableFields'
import { cloneQuestion } from '../configs/questionTypesAdminConfig'

const splitPage = (args, state, { changeValue }) => {
  const [, questionIndex, stepIndex] = args
  changeValue(state, 'steps_attributes', stepsAttributes => {
    const currentStepQuestions = stepsAttributes[stepIndex].questions_attributes
    const questionsForNewStep = cloneAttributesSlice(currentStepQuestions, questionIndex + 1)
    stepsAttributes[stepIndex].questions_attributes = destroyAttributesSlice(currentStepQuestions, questionIndex + 1)
    stepsAttributes.splice(stepIndex + 1, 0, { questions_attributes: questionsForNewStep })
    return stepsAttributes
  })
}

const cloneAttributesSlice = (attributes, startIndex = 0, endIndex) =>
  attributes.slice(startIndex, endIndex).map(attribute => cloneQuestion(attribute))

const destroyAttributesSlice = (attributes, startIndex, endIndex) => {
  const destroyedAttributes = attributes.slice(startIndex, endIndex).map(attribute => ({ ...attribute, _destroy: '1' }))
  attributes.splice(startIndex, destroyedAttributes.length, ...destroyedAttributes)
  return attributes
}

const joinPage = (args, state, { changeValue }) => {
  const [, stepIndex, direction] = args
  changeValue(state, 'steps_attributes', stepsAttributes => {
    let toIndex = getNextActiveIndex(stepsAttributes, stepIndex)
    if (direction === 'up') {
      toIndex = getPrevActiveIndex(stepsAttributes, stepIndex)
    }
    const destinyStepQuestions = stepsAttributes[toIndex].questions_attributes
    const currentStepQuestions = cloneAttributesSlice(stepsAttributes[stepIndex].questions_attributes)
    if (direction === 'up') {
      stepsAttributes[toIndex].questions_attributes = destinyStepQuestions.concat(currentStepQuestions)
    } else {
      stepsAttributes[toIndex].questions_attributes = currentStepQuestions.concat(destinyStepQuestions)
    }
    stepsAttributes[stepIndex]._destroy = '1'
    return stepsAttributes
  })
}

export default { splitPage, joinPage }
