import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import { Button } from '../../atoms/Button'
import { HtmlField } from '../../molecules/fields/HtmlField'
import StepsFields from '../forms/StepsFields'
import { setupSerializedFormData } from '../../../configs/formsConfig'
import { clearMutator, customSwapMutator, markForDestroyMutator } from '../../../utils/formMutators'
import pageMutators from '../../../utils/pageMutators'
import { focusOnFirstError } from '../../../utils/decorators'
import { FormTypeContext } from '../../../utils/contexts'
import TaskFormSetup from './TaskFormSetup'
import { getSpecialTaskType } from '../../../configs/questionTypesAdminConfig'

const TaskForm = ({ title, buttonLabel, initialValues, onSave, onClose }) => {
  const defaultTask = 'defaultTask'
  const [taskType, setTaskType] = useState(defaultTask)
  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    try {
      await onSave(formData)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  const handleChangeDefaultQuestion = kind => {
    setTaskType(getSpecialTaskType(kind) || defaultTask)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
        swap: customSwapMutator,
        clear: clearMutator,
        markForDestroy: markForDestroyMutator,
        ...pageMutators
      }}
      initialValues={initialValues}
      decorators={[focusOnFirstError]}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="flex flex-col h-full">
          <div className="header py-4 px-4 md:px-0">
            <h2 className="text-xl font-bold">{title}</h2>
            <div>Configura y agrega las preguntas para tu tarea.</div>
          </div>
          <div className="body flex-1 md:overflow-y-auto">
            <div className=" bg-neutral-100 p-4 md:p-6 mb-6">
              <h3 className="text-2xl font-bold mb-6">Configuración de la tarea</h3>
              <TaskFormSetup initialValues={initialValues} onChangeDefaultQuestions={handleChangeDefaultQuestion} />
            </div>
            <div className=" bg-neutral-100 p-4 md:p-6">
              <h3 className="text-2xl font-bold mb-6">Formulario</h3>
              <HtmlField
                name="hint"
                label="Información general del formulario"
                hint="Es la información que se le dará al funcionario para ayudarlo a realizar la tarea"
                validate={validationsAdmin.isRichTextFieldEmpty()}
              />
              <div className="font-bold mb-2">Preguntas</div>
              <div className="mb-4">
                Define las preguntas que componen la tarea. Estas deberán ser completadas por el usuario que realiza la
                tarea y las respuestas le llegarán al usuario que recibe el trámite.
              </div>
              <FormTypeContext.Provider value={taskType}>
                <StepsFields addPages={false} />
              </FormTypeContext.Provider>
            </div>
          </div>
          <div className="footer flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
            <Button variant="secondary" className="md:max-w-56 lg:max-w-72 md:mr-3" size="full" onClick={onClose}>
              Cerrar
            </Button>
            <Button
              type="submit"
              className="md:max-w-56 lg:max-w-72 mb-3 md:mb-0"
              size="full"
              disabled={submitting || pristine}
            >
              {buttonLabel}
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
export default TaskForm

TaskForm.propTypes = {
  buttonLabel: PropTypes.string,
  initialValues: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string
}
