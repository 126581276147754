import PropTypes from 'prop-types'
import React from 'react'
import SelectFieldFilter from '../../molecules/filters/SelectFieldFilter'
import useTutorial from '../../../hooks/useTutorial'
import useUpgrade from '../../../hooks/useUpgrade'

const CreatorsFilter = ({ type = '', suffix = '' }) => {
  const tutorialHook = useTutorial()
  const upgradeHook = useUpgrade()
  const { getCreators } = type === 'Tutorial' ? tutorialHook : upgradeHook
  const { data: creators } = getCreators([`creators${type}`])

  return <SelectFieldFilter className="mt-3" label="Creado por" options={creators || []} name={`created_by${suffix}`} />
}

export default CreatorsFilter

CreatorsFilter.propTypes = {
  suffix: PropTypes.string,
  type: PropTypes.string
}
