import React, { useEffect } from 'react'
import PageHeading from '../../../molecules/PageHeading'
import useFilters from '../../../../hooks/useFilters'
import { urlBase } from '../../../../hooks/useUpgrade'
import CardDescription from '../../../organisms/onboarding/CardDescription'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import { BoxTable } from '../../../molecules/BoxTable'
import { urlToJson } from '../../../../utils/helpers'

const Upgrades = () => {
  const {
    results: upgrades,
    initialFilters,
    onFilter,
    filters
  } = useFilters(urlToJson(urlBase), { sort_u: 'create_desc', per_u: 12 }, { pageName: 'page_u' })

  useEffect(() => {
    onFilter({ ...filters, per_u: 12 }, false)
  }, [])

  const optionsOrderBy = [
    { value: 'create_desc', label: 'Más recientes primero' },
    { value: 'create_asc', label: 'Más antiguos primero' }
  ]

  return (
    <>
      <PageNavigation />
      <Container>
        <PageHeading title="Actualizaciones" pageDescription="Descubre lo nuevo en Facilita" />
        <BoxTable
          values={upgrades}
          onFilter={onFilter}
          initialFilters={initialFilters}
          searchPlaceholder="Buscar por nombre"
          sortByName="sort_u"
          searchFieldName="search_u"
          paginationLabel="page_u"
          sortOptions={optionsOrderBy}
          displayHeader={false}
          className="-mt-2"
        >
          <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3 pt-6 pb-2 border-solid border-neutral-400 border-t-0.375">
            {upgrades &&
              upgrades.data.map(upgrade => (
                <CardDescription
                  key={upgrade.id}
                  to={`/centro-de-ayuda/articulo-actualizacion/${upgrade.id}`}
                  name={upgrade.name}
                  description={upgrade.description}
                  descriptionLines={5}
                />
              ))}
          </div>
        </BoxTable>
      </Container>
    </>
  )
}
export default Upgrades
