import { useAuth } from '../../components/molecules/Auth'
import useHasRolePermissions from '../useHasRolePermissions'
import { roles, types } from '../../configs/permissionsConfig'

const { admin, coordinator, collaborator, branch_coordinator: branchCoordinator } = roles
const { quickSearch: quickSearchType } = types

const QuickSearch = () => {
  const { user } = useAuth()
  const hasRolePermissions = useHasRolePermissions()

  const canCreate = hasRolePermissions([
    admin,
    coordinator,
    branchCoordinator,
    [collaborator, quickSearchType, 'create_or_edit']
  ])
  const canCreateOrEdit = canCreate
  const isAnAllowedQuickSearch = quickSearch => user.allowed_quick_searches?.includes(quickSearch?.id)
  const canEdit = quickSearch =>
    hasRolePermissions([
      admin,
      coordinator,
      branchCoordinator,
      {
        rolePermission: [collaborator, quickSearchType, 'create_or_edit'],
        additionalCondition: isAnAllowedQuickSearch(quickSearch)
      }
    ])
  const canDelete = quickSearch =>
    hasRolePermissions([
      admin,
      coordinator,
      branchCoordinator,
      {
        rolePermission: [collaborator, quickSearchType, 'create_or_edit'],
        additionalCondition: isAnAllowedQuickSearch(quickSearch) && quickSearch?.created_by === user.id
      }
    ])

  const canNotSeeExcelUrl = hasRolePermissions([admin])

  return { canCreate, canEdit, canCreateOrEdit, canDelete, canNotSeeExcelUrl }
}

export default QuickSearch
