import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-final-form'
import SelectField from './SelectField'
import { dataToOptions } from '../../../utils/helpers'
import useFieldValue from '../../../hooks/useFieldValue'
import useUbigeos from '../../../hooks/useUbigeos'

const UbigeoFieldFilter = ({ name, label }) => {
  const { change } = useForm()
  const regionId = useFieldValue(`${name}_region`)
  const provinceId = useFieldValue(`${name}_province`)
  const { getRegions, getProvinces, getDistricts } = useUbigeos()
  const { data: regions } = getRegions()
  const { data: provinces } = getProvinces(regionId)
  const { data: districts } = getDistricts(provinceId)

  return (
    <>
      <SelectField
        label={label || 'Ubicación'}
        options={dataToOptions(regions)}
        name={`${name}_region`}
        margin="lowdense"
        className="min-w-60"
        onChange={() => {
          change(`${name}_province`, '')
          change(`${name}_district`, '')
        }}
        size="full"
      />
      <SelectField
        options={dataToOptions(provinces)}
        name={`${name}_province`}
        margin="lowdense"
        className="min-w-60"
        onChange={() => change(`${name}_district`, '')}
        size="full"
      />
      <SelectField
        options={dataToOptions(districts)}
        name={`${name}_district`}
        margin="lowdense"
        className="min-w-60"
        size="full"
      />
    </>
  )
}

export default UbigeoFieldFilter

UbigeoFieldFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired
}
