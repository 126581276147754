import useCrud from './useCrud'
import axios from 'axios'

const urlBaseForm = `/admin/v1/quick_searches`

const useQuickSearch = () => {
  const { findById, create, get: getQuickSearches } = useCrud(`${urlBaseForm}`)

  const { get: getAllQuickSearchIds } = useCrud(`${urlBaseForm}/all_quick_searches_ids`)

  const getQuickSearch = id => findById(['institution', id])

  const createQuickSearch = (id, values, options) => create(values, options)

  const updateQuickSearch = async (id, values, config) => {
    const data = await axios.put(`${urlBaseForm}/${id}.json`, values, config)
    config.onSuccess && config.onSuccess(data)
  }

  const { get: getCreators } = useCrud(`${urlBaseForm}/creators`)

  return { createQuickSearch, getQuickSearch, updateQuickSearch, getQuickSearches, getAllQuickSearchIds, getCreators }
}

export default useQuickSearch
