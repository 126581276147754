import React from 'react'
import Container from '../../atoms/Container'
import { Button } from '../../atoms/Button'

export const GetAccess = () => {
  return (
    <section className="py-16 bg-neutral-100 px-8 md:px-0">
      <Container className="md:px-36">
        <div className="bg-blue-400 py-6 md:py-12 px-6 md:px-10 flex justify-center md:justify-between flex-wrap md:flex-nowrap items-center">
          <strong className="text-base md:text-2xl leading-normal text-center md:text-left mb-6 md:mb-0">
            ¿Tú entidad aún no tiene acceso a Facilita?
          </strong>
          <a href="https://www.gob.pe/10899" target="_blank" rel="noreferrer">
            <Button className="hover:text-white" size="m">
              Obtener acceso
            </Button>
          </a>
        </div>
      </Container>
    </section>
  )
}
