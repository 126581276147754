import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Button } from '../../atoms/Button'
import arrayMutators from 'final-form-arrays'
import { focusOnFirstError } from '../../../utils/decorators'
import FormsHeader from '../../molecules/FormsHeader'
import { clearMutator, customSwapMutator, markForDestroyMutator } from '../../../utils/formMutators'
import pageMutators from '../../../utils/pageMutators'
import SaveButton from '../../molecules/buttons/SaveButton'
import { setupSerializedFormData } from '../../../configs/formsConfig'
import ScrollToTop from '../../molecules/ScrollToTop'

const Wizard = ({
  stepPages,
  mutateForm,
  initialValues,
  headerInfo,
  stepLabels,
  textButtons = { lastStep: 'Guardar y continuar', previousStep: 'Guardar y continuar' },
  defaultStep,
  makeSerialize = true
}) => {
  const [step, setStep] = useState(defaultStep ? defaultStep - 1 : 0)
  const [uploadProgress, setUploadProgress] = useState(null)
  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const convertToSubmit = async values => {
    const formData = makeSerialize ? setupSerializedFormData(values) : values
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    try {
      await mutateForm({ values: formData, config })
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  const lastStepIndex = stepPages.length - 1
  const isLastStep = lastStepIndex === step
  const isFirstStep = step === 0
  const handleSubmit = values =>
    isLastStep ? convertToSubmit(values) : setStep(() => Math.min(step + 1, lastStepIndex))
  const onPrevClick = () => setStep(() => Math.max(step - 1, 0))

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
        clear: clearMutator,
        swap: customSwapMutator,
        markForDestroy: markForDestroyMutator,
        ...pageMutators
      }}
      decorators={[focusOnFirstError]}
      subscription={{ values: true }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {headerInfo && (
            <FormsHeader
              title={headerInfo.title}
              hint={headerInfo.hint}
              steps={stepLabels}
              activeStep={stepLabels[step]}
            />
          )}
          {stepPages[step]}
          <div className="flex gap-6 justify-end px-6 md:px-0 mb-6 flex-col md:flex-row flex-col-reverse">
            {!isFirstStep && (
              <Button
                className="md:max-w-56 mb-4 md:mb-0"
                type="button"
                onClick={onPrevClick}
                size="full"
                variant="secondary"
              >
                Regresar
              </Button>
            )}
            {step >= 0 ? (
              <div className="w-full md:max-w-72 justify-end">
                <SaveButton
                  label={isLastStep ? textButtons.lastStep : textButtons.previousStep}
                  uploadProgress={uploadProgress}
                />
              </div>
            ) : null}
          </div>
          <ScrollToTop trigger={step} />
        </form>
      )}
    </Form>
  )
}

export default Wizard

Wizard.propTypes = {
  defaultStep: PropTypes.number,
  headerInfo: PropTypes.object,
  initialValues: PropTypes.object,
  makeSerialize: PropTypes.bool,
  mutateForm: PropTypes.func,
  stepLabels: PropTypes.array,
  stepPages: PropTypes.array,
  textButtons: PropTypes.object
}
