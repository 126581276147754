import React, { Fragment } from 'react'
import axios from 'axios'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Container from '../../../atoms/Container'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Button } from '../../../atoms/Button'
import { useMutation, useQueryClient } from 'react-query'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import EditIcon from '@material-ui/icons/Edit'
import PublishStatusSection from '../../../organisms/forms/PublishStatusSection'
import FormsHeader from '../../../molecules/FormsHeader'
import { FormSteps, formValuesPreview } from '../../../../configs/templatesConfig'
import { AppBar, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import CitizenFormContainer from '../../../organisms/citizens/CitizenFormContainer'
import Header from '../../../molecules/Header'
import FormsNewFromTemplate from '../forms/FormsNewFromTemplate'
import useTemplate from '../../../../hooks/useTemplate'
import queryString from 'query-string'
import Alert from '../../../molecules/Alert'

const updatePublished = id => values => axios.put(`/admin/v1/templates/${id}.json`, values)

const TemplatePreview = () => {
  const { id: templateId } = useParams()
  const { search } = useLocation()
  const queryClient = useQueryClient()
  const urlSearchParams = queryString.parse(search)
  const { data: template, status } = useTemplate(templateId)
  const { mutate } = useMutation(updatePublished(templateId), {
    onSuccess: () => queryClient.invalidateQueries('template')
  })
  const [tabSelected, setTabSelected] = React.useState('templateTab')
  const handleChangeTab = (event, newValue) => setTabSelected(newValue)
  if (status !== 'success') return null
  return (
    <Fragment>
      <PageNavigation />
      <Container className="py-12">
        {urlSearchParams.action === 'new' ? (
          <FormsHeader
            title="Crear una plantilla de formulario"
            hint="Crear una plantilla para las instituciones públicas usuarias de Facilita."
            steps={FormSteps}
            activeStep={FormSteps.PREVIEW}
          />
        ) : (
          <h1 aria-describedby="page-description" className="title mb-8 mx-4 md:mx-0">
            Vista previa: {template.name}
          </h1>
        )}
        {urlSearchParams.action === 'edit' && (
          <Alert type="success" className="mb-8">
            Cambios guardados exitosamente
          </Alert>
        )}
        <section className="mb-8 box-content border-2 border-neutral-400 mx-4 md:mx-0">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div className="self-baseline py-4 px-6">
              <p className="font-bold text-xl mb-2">Publicación</p>
              <p>
                Una vez publicada tu plantilla, los funcionarios públicos podrán verla en la opción{' '}
                <strong>Usar una plantilla</strong> en el primer paso de <strong>Crear formulario</strong>.
              </p>
            </div>
            <div className="bg-neutral-100 p-4 flex items-center justify-center">
              <PublishStatusSection canChangeState mutate={mutate} item={template} />
            </div>
          </div>
        </section>
        <section className="mb-16">
          <div className="mb-6 mx-4 md:mx-0">
            <h2 className="font-bold text-2xl mb-2 text-primary flex items-center w-fit">
              <VisibilityIcon className="mr-4" />
              Estas son la plantilla y el formulario que verá el funcionario público:
            </h2>
          </div>
          <div className="border-4 border-neutral-300 mb-8">
            <TabContext value={tabSelected}>
              <AppBar position="static" color="default" classes={{ colorDefault: 'bg-white' }}>
                <TabList onChange={handleChangeTab} variant="fullWidth" classes={{ indicator: 'h-1 bg-red-700 top-0' }}>
                  <Tab
                    label="Plantilla"
                    value="templateTab"
                    classes={{ selected: 'bg-neutral-200 text-red-700 font-bold' }}
                  />
                  <Tab
                    label="Formulario"
                    value="formTab"
                    classes={{ selected: 'bg-neutral-200 text-red-700 font-bold' }}
                  />
                </TabList>
              </AppBar>
              <TabPanel value="templateTab" classes={{ root: 'p-0' }}>
                <Header />
                <FormsNewFromTemplate templateId={templateId} isPreview />
              </TabPanel>
              <TabPanel value="formTab" classes={{ root: 'p-0' }}>
                <CitizenFormContainer form={formValuesPreview(template)} isPreview />
              </TabPanel>
            </TabContext>
          </div>
          <div className="flex justify-center md:justify-end">
            <Button
              size="l"
              variant="secondary"
              el={Link}
              to={`/plantillas/${templateId}/editar`}
              className="bg-neutral-200"
              startIcon={<EditIcon />}
            >
              Editar mi plantilla
            </Button>
          </div>
        </section>
      </Container>
    </Fragment>
  )
}

export default TemplatePreview
