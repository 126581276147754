import PropTypes from 'prop-types'
import React from 'react'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import Error from '../../molecules/Error'
import { disableTypeOptions, getLabelToNext } from '../../../configs/procedureManagementsConfig'
import Value from '../../molecules/Value'
import ArrayTasks from './ArrayTasks'
import { useForm } from 'react-final-form'
import useFieldValue from '../../../hooks/useFieldValue'

const NextDisabledTasksSection = ({ task }) => {
  const {
    mutators: { clear }
  } = useForm()
  const originUserTypeId = useFieldValue('origin_user_type_id')

  return (
    <div className="relative js-field-container">
      <SelectField
        label={getLabelToNext('disable', task, task.origin_user_type_name)}
        name="disabled_tasks.type"
        options={disableTypeOptions}
        containerClassName="flex-1 sm:max-w-lg md:max-w-full"
        size="4xl"
        validate={validations.required()}
        emptyLabel="Selecciona una opción"
        margin="none"
        onChange={() => clear('disabled_tasks.list')}
      />
      <Value name="disabled_tasks.type">
        {({ value: type }) =>
          type === 'specify_tasks' && (
            <div className="pl-5 py-6 mb-6 grid grid-cols-1 bg-neutral-200 mt-4">
              Selecciona las tareas que se deshabilitarán:
              <ArrayTasks name="disabled_tasks.list" userTypeId={originUserTypeId} />
            </div>
          )
        }
      </Value>
      <Error name="disable_tasks" />
    </div>
  )
}
export default NextDisabledTasksSection

NextDisabledTasksSection.propTypes = {
  task: PropTypes.object.isRequired
}
