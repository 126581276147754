import React from 'react'
import axios from 'axios'
import Container from '../../../atoms/Container'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import FormsForm from '../../../organisms/forms/FormsForm'
import FormsHeader from '../../../molecules/FormsHeader'

const fetchForm = formId => axios.get(`/admin/v1/forms/${formId}.json`).then(a => a.data)

const updateForm = ({ values, config, formId }) =>
  axios.put(`/admin/v1/forms/${formId}.json`, values, config).then(res => res.data)

const FormsEdit = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id: formId } = useParams()
  const { data: form, status } = useQuery(['form', formId], () => fetchForm(formId), { refetchOnWindowFocus: false })

  const handleFormUpdate = async ({ values, config }) => {
    try {
      await updateForm({ values, config, formId })
      await queryClient.invalidateQueries(['form', formId])
      history.push(`/formularios/${formId}/vista-previa?action=edit`)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="pt-12">
        <FormsHeader
          title={`Editando formulario: ${form.name}`}
          hint="Edita el formulario digital"
          description="Las ediciones realizadas no alterán las respuestas anteriores.
          Si los cambios son drásticos considera crear otro formulario y despublicar este."
        />
        <FormsForm
          initialValues={form}
          config={form.config}
          mutateForm={handleFormUpdate}
          buttonMessage="Guardar"
          noPristineToDisable
        />
      </Container>
    </>
  )
}

export default FormsEdit
