import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG } from 'react-svg'
import DocIdDocTypeField from '../question_config_fields/DocIdDocTypeField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import DocumentTypeIcon from '../../../images/icons/document_type_icon.svg'
import DocIdTypeCitizenField from '../question_fields/DocIdTypeCitizenField'
import Alert from '../../molecules/Alert'

const Form = props => {
  return <DocIdDocTypeField baseName={props.baseName} />
}

const Template = props => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <div>
        <DocIdTypeCitizenField payload={props.question.payload} name={props.name} disabled noDefaultValue />
      </div>
      <Alert type="info" border>
        Se validará el formato del número de documento según el tipo de documento que el usuario seleccione. Las dos
        preguntas se harán juntas.
      </Alert>
    </div>
  )
}

const Answer = props => {
  const { title, content } = getTitleContent(props)
  return <BoldLabelAndContent label={title} content={<p>{content}</p>} el="li" />
}

const TaskResponse = props => {
  const { title, content } = getTitleContent(props)
  return <BoldLabelAndContent label={title} content={<p>{content}</p>} className="mb-0" />
}

const getTitleContent = ({ answer, question }) => ({
  title: `${question.payload.doc_type_label}: ${question.payload.doc_id_label}`,
  content: `${answer.doc_type_name}: ${answer.doc_id}`
})

const DocTypeQuestion = {
  selectOption: {
    value: 'doc_id_type',
    label: 'Tipo y número de documento de identidad',
    icon: <ReactSVG src={DocumentTypeIcon} />
  },
  Form,
  Template,
  Answer,
  TaskResponse,
  name: 'DocTypeQuestion'
}

export default DocTypeQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
