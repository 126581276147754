import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import FormSection from '../../molecules/FormSection'
import arrayMutators from 'final-form-arrays'
import { customSwapMutator, clearMutator, markForDestroyMutator } from '../../../utils/formMutators'
import pageMutators from '../../../utils/pageMutators'
import { focusOnFirstError, preventSubmitPrivacyPolicy } from '../../../utils/decorators'
import FormsFormSetup from './FormsFormSetup'
import Instructions from '../../molecules/Instructions'
import StepsFields from './StepsFields'
import PrivacyPolicyCreator from './PrivacyPolicyCreator'
import SaveButton from '../../molecules/buttons/SaveButton'
import { Form, Field } from 'react-final-form'
import { getConfig, getInstructions, setupSerializedFormData } from '../../../configs/formsConfig'
import BeforeunloadForm from './BeforeunloadForm'
import useSearchParams from '../../../hooks/useSearchParams'

const FormsForm = ({
  config: customConfig = {},
  initialValues,
  mutateForm,
  buttonMessage = 'Guardar y ver vista previa',
  noPristineToDisable = false
}) => {
  const config = getConfig(customConfig)
  const [uploadProgress, setUploadProgress] = useState(null)
  const searchParams = useSearchParams()
  const acceptedCivilRegistrationConditions = searchParams.get('accepted')
  const approvedTupa = searchParams.get('approvedTupa')

  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    try {
      await mutateForm({ values: formData, config })
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
        swap: customSwapMutator,
        clear: clearMutator,
        markForDestroy: markForDestroyMutator,
        ...pageMutators
      }}
      initialValues={initialValues}
      decorators={[focusOnFirstError, preventSubmitPrivacyPolicy]}
      subscription={{ pristine: true, submitting: true }}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <BeforeunloadForm pristine={pristine} submitting={submitting} />
          <FormsFormSetup config={config} />
          <FormSection title="Preguntas del formulario" bottomSpace={false}>
            <Instructions list={getInstructions(config.from_template)} />
            <FieldsSeparator className="my-8" />
            <StepsFields
              addQuestions={config.add_questions}
              addPages={config.add_questions}
              sortQuestions={config.sort_questions}
              editDeleteQuestions={config.edit_delete_questions}
            />
            <FieldsSeparator />
            <PrivacyPolicyCreator exampleFileUrl={config.privacy_policy?.url} required={false} />
            <FieldsSeparator />
            <SaveButton
              label={buttonMessage}
              uploadProgress={uploadProgress}
              disabled={(!noPristineToDisable && pristine) || submitting}
            />
          </FormSection>
          <Field
            component="input"
            className="hidden"
            name="civil_registration_conditions.accepted"
            initialValue={acceptedCivilRegistrationConditions}
          />
          <Field
            component="input"
            className="hidden"
            name="civil_registration_conditions.approvedTupa"
            initialValue={approvedTupa}
          />
        </form>
      )}
    </Form>
  )
}
export default FormsForm

FormsForm.propTypes = {
  buttonMessage: PropTypes.string,
  config: PropTypes.object,
  initialValues: PropTypes.object,
  mutateForm: PropTypes.func.isRequired,
  noPristineToDisable: PropTypes.bool
}
