import { getNextActiveIndex, getPrevActiveIndex } from './destroyableFields'
import { cloneQuestion } from '../configs/questionTypesAdminConfig'
import * as uuid from 'uuid'

export const customSwapMutator = (args, state, tools) => {
  const [field, fromIndex, toIndex, direction] = args
  if (toIndex !== undefined) {
    const currently = tools.getIn(state, `formState.values.${field}`)
    const fromIndexValue = currently[fromIndex]
    const toIndexValue = currently[toIndex]
    tools.changeValue(state, field, value => {
      value[toIndex] = { ...fromIndexValue, key: uuid.v4() }
      value[fromIndex] = { ...toIndexValue, key: uuid.v4() }
      return value
    })
  } else {
    const numberInBrackets = /\[(\d+)\]/
    const stepIndex = parseInt(field.match(numberInBrackets)[1])
    tools.changeValue(state, 'steps_attributes', stepsAttributes => {
      const questionToMove = cloneQuestion(stepsAttributes[stepIndex].questions_attributes[fromIndex])
      stepsAttributes[stepIndex].questions_attributes[fromIndex]._destroy = '1'
      if (direction === 'up') {
        const prevActiveIndex = getPrevActiveIndex(stepsAttributes, stepIndex)
        stepsAttributes[prevActiveIndex].questions_attributes.push(questionToMove)
      } else {
        const nextActiveIndex = getNextActiveIndex(stepsAttributes, stepIndex)
        stepsAttributes[nextActiveIndex].questions_attributes.splice(0, 0, questionToMove)
      }
      return stepsAttributes
    })
  }
}

export const clearMutator = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined)
}

export const markForDestroyMutator = (args, state, { changeValue }) => {
  const [field, index] = args
  changeValue(state, field, (values = []) => {
    const currentValue = values?.[index] || {}
    return Object.assign([], values, { [index]: { ...currentValue, _destroy: '1' } })
  })
}
