import React, { useState } from 'react'
import { Chart } from 'react-google-charts'
import { SelectInput } from '../../../molecules/fields/SelectField'
import { MenuItem, Typography } from '@material-ui/core'
import { Button } from '../../../atoms/Button'
import PropTypes from 'prop-types'

const CustomChart = ({ chartData, title, description, titleOptions, titleTime }) => {
  const [chartType, setChartType] = useState('LineChart')
  const [chartImageURI, setChartImageURI] = useState('')

  const handleChartTypeChange = event => {
    setChartType(event.target.value)
  }

  const options = {
    curveType: 'function',
    legend: { position: 'bottom' },
    hAxis: {
      title: titleTime,
      titleTextStyle: {
        bold: true
      }
    },
    vAxis: {
      title: titleOptions,
      titleTextStyle: {
        bold: true
      }
    },
    pointSize: 9,
    lineWidth: 2
  }

  const chartEvents = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart()
        setChartImageURI(chart.getImageURI())
      }
    }
  ]

  const downloadChart = () => {
    if (chartImageURI) {
      const downloadLink = document.createElement('a')
      downloadLink.href = chartImageURI
      downloadLink.download = 'chart.png'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  return (
    <>
      <div className="md:flex block overflow-auto space-x-6 px-6 py-8 bg-neutral-100 row scrollable">
        <div className="md:w-5/6 w-full">
          <div className="md:flex block justify-between pb-4 md:pb-0 items-center">
            <div className="md:w-5/6 w-full">
              <h2 className="md:text-left w-full md:w-auto font-bold text-2xl">{title}</h2>
              <p className="my-4">{description}</p>
            </div>
            <div className="md:w-1/6 w-full">
              <SelectInput value={chartType} onChange={handleChartTypeChange}>
                {[
                  { id: 'LineChart', name: 'Gráfico lineal' },
                  { id: 'ColumnChart', name: 'Gráfico de barras' }
                ].map(option => (
                  <MenuItem value={option.id} key={option.id}>
                    <Typography className="text-base whitespace-normal leading-none pl-6 pr-6 py-2">
                      {option.name}
                    </Typography>
                  </MenuItem>
                ))}
              </SelectInput>
            </div>
          </div>
        </div>
        <div className="md:w-1/6"></div>
      </div>

      <div className="md:flex block space-x-6 px-6 bg-neutral-100 pb-8 row">
        <div className="md:w-5/6 w-full">
          <Chart
            chartType={chartType}
            width="100%"
            height="500px"
            data={chartData}
            options={options}
            chartEvents={chartEvents}
          />
        </div>
        <div className="md:w-1/6">
          <div className="px-4">
            <p className="font-bold">Opciones de descarga</p>
            <div className="mb-8 mt-4">
              <input type="radio" id="downloadOption" name="download" defaultChecked />
              <label htmlFor="downloadOption" className="ml-2">
                Gráfico
              </label>
            </div>
            <Button onClick={downloadChart} variant="secondary" size="full" className="md:max-w-40">
              Descargar
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomChart

CustomChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleOptions: PropTypes.string.isRequired,
  titleTime: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}
